import { Theme, useTheme } from "../../state/theme";
import { Roulette } from "./roulette";
import { Sweeps } from "./sweeps";
import { useOfferConfig } from "../../state/offer_config";
import React from "react";
import { useUserState } from "../../state/user_state";
import { startupParameters } from "../../state/startup";
import { Info } from "./unlock_info";

export const Unlock = () => {
    const theme = useTheme();
    const offer = useOfferConfig();
    const state = useUserState();
    switch (theme) {
        case Theme.LOTTERY:
            return (
                <Roulette
                    borderImage={`${startupParameters.cdn.manifest}/image/roulette-border.svg`}
                    spinnerImage={`${startupParameters.cdn.manifest}/image/roulette-spinner.svg`}
                    cashPrize={offer?.promoAmount ?? "$1,000.00"}
                    officialRulesLink="#"
                    prizePaymentMethod={
                        state.step.subHeader ??
                        offer?.promoText ??
                        "Sent to your CashApp"
                    }
                    onNext={() => {
                        state.nextStep();
                    }}
                />
            );
        case Theme.INFO:
            return <Info />;
        default:
            return <Sweeps />;
    }
};
