import { create } from "zustand";
import { shallow } from "zustand/shallow";
import { createJSONStorage, persist, subscribeWithSelector, } from "zustand/middleware";
import { md5 } from "js-md5";
import { canSkipToConfirmation, replaceNextSkippableStepsWithSingleConfirmationStep } from "../helpers/step_skipper";
import type { OfferConfig, PathConfig, WebsiteConfig, DomainConfig } from "../models/config";
import { StepConfig } from "../models/step_config";
import { lookupZip } from "../api/zip_lookup";
import { stateToIsoCode } from "../helpers/full_state_to_iso";
import { GenderType } from "../models/gender";
import { Quiz } from "../models/quiz";
import { LinkOut } from "../api/pivot";
import { isPathStep, isRewardStep } from "../helpers/steps";
import { Stage, trackUserProgress, trackUserStep } from "../tracking/user";
import { updateSDPSUser } from "../helpers/sdps_helper";
import { waitForSessionToken } from "../tracking/helper";
import { getTrackingSession } from "../tracking";
import { EventType } from "@pushnami/models-pivot-tracking/dist/models";
import {startupParameters} from "./startup";

export enum Step {
    Address = "address",
    AddressPhoneZip = "address_phone_zip",
    DateOfBirth = "dob",
    Email = "email",
    EmailPhone = "email_phone",
    EmailV2 = "email_v2",
    FirstLastDobGender = "first_last_dob_gender",
    FirstLastPhoneAddressDobGender = "first_last_phone_address_dob_gender",
    Gender = "gender",
    Loading = "loading",
    LookingForOffersV1 = "looking_for_offers_v1",
    Name = "name",
    Path = "path",
    PathSecondary = "secondaryPlacement",
    Phone = "phone",
    Quiz = "quiz",
    OfferWall = "offer_wall",
    Reward = "reward",
    Unlock = "unlock",
    UnlockSamples = "unlock_samples",
    ZipCode = "zipCode",
    LinkOut = "link_out",
    ThankYou = "thank_you",
    AutoRegConfirmation = "auto_reg_confirmation",
}

export interface State {
    firstName: string | null;
    lastName: string | null;
    zipCode: string | null;
    phone: string | null;
    email: string | null;
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    day: string | null;
    month: string | null;
    year: string | null;
    gender: GenderType | null;
    phoneAllowed: boolean;
    psfp: string | null;
    quizzes: Quiz[] | null;
    leadId: string | null;
    trustedFormUrl: string | null;
    websiteConfig: WebsiteConfig | null;
    pathConfig: PathConfig | null;
    offerConfig: OfferConfig | null;
    domainConfig: DomainConfig | null;
    placementId: string | null;
    zeetoLoaded: boolean;
    linkOuts: LinkOut[] | null;
    metadataKeys: string[] | null;

    step: StepConfig;
    path: StepConfig[];

    goToReward: () => void;
    nextStep: () => void;
    getNextStep: () => StepConfig | null;
    setZipCode: (value: string) => void;
    setFirstName: (firstName: string | null) => void;
    setLastName: (lastName: string | null) => void;
    setEmail: (email: string | null) => void;
    setPhone: (phone: string | null) => void;
    setAddress1: (address: string) => void;
    setAddress2: (address: string) => void;
    setState: (address: string) => void;
    setCity: (address: string) => void;
    setYear: (year: string) => void;
    setDay: (day: string) => void;
    setMonth: (month: string) => void;
    setGender: (gender: GenderType | null) => void;
    setPhoneAllowed: (value: boolean) => void;
    getDateOfBirth: () => string | null;
    setPsfp: (psfp: string) => void;
    setQuiz: (quiz: Quiz) => void;
    setLeadId: (leadId: string) => void;
    setTrustedFormUrl: (url: string) => void;
    setWebsiteConfig: (config: WebsiteConfig) => void;
    setPathConfig: (config: PathConfig) => void;
    setOfferConfig: (config: OfferConfig) => void;
    setPlacement: (placement: string | null) => void;
    setZeetoLoaded: (loaded: boolean) => void;
    setLinkOuts: (linkOuts: LinkOut[]) => void;
    addMetadataKeys: (keys: string[]) => void;
    setDomainConfig: (config: DomainConfig) => void;
}

export const useUserState = create<State>()(
    subscribeWithSelector(
        persist(
            (set, get) => ({
                path: [{ step: Step.Loading }],
                step: { step: Step.Loading },
                firstName: null,
                lastName: null,
                zipCode: null,
                phone: null,
                email: null,
                address1: null,
                address2: "",
                city: null,
                state: null,
                psfp: null,
                day: null,
                month: null,
                year: null,
                gender: null,
                phoneAllowed: false,
                quizzes: null,
                leadId: null,
                trustedFormUrl: null,
                websiteConfig: null,
                pathConfig: null,
                offerConfig: null,
                placementId: null,
                zeetoLoaded: false,
                linkOuts: null,
                metadataKeys: null,
                domainConfig: null,
                setPlacement: (placement) => {
                    set({ placementId: placement });
                },
                goToReward: () => {
                    set({ step: { step: Step.Reward } });
                },
                setFirstName: (firstName) => set({ firstName }),
                setLastName: (lastName) => set({ lastName }),
                setZipCode: async (value) => {
                    set({ zipCode: value });
                    const zipInfo = await lookupZip(value);
                    if (zipInfo) {
                        const isoStateCode = stateToIsoCode(zipInfo.state);

                        const state = get();
                        set({
                            state: state.state ? state.state : isoStateCode,
                            city: state.city ?? zipInfo.city,
                        });
                    }
                },
                nextStep: () => {
                    const state = get();
                    let nextStep: StepConfig | null;

                    if (startupParameters.ux.autoRegister && canSkipToConfirmation(state)) {
                        nextStep = replaceNextSkippableStepsWithSingleConfirmationStep(state, set)
                    } else {
                        nextStep = state.getNextStep();
                    }

                    if (nextStep) {
                        set({ step: nextStep });
                    } else {
                        state.goToReward();
                    }
                },
                getNextStep: () => {
                    const state = get();
                    const currentStepIndex = state.path.indexOf(state.step);
                    if (currentStepIndex === -1) {
                        // the current step has been forced to a step that is not in the path, so there's
                        // no next step since the path flow has been broken
                        return null;
                    }
                    return state.path[currentStepIndex + 1]
                },
                setEmail: (email) => set({ email }),
                setPhone: (phone) => set({ phone }),
                setPhoneAllowed: (value: boolean) =>
                    set({ phoneAllowed: value }),
                setAddress1: (address: string) => set({ address1: address }),
                setAddress2: (address: string) => set({ address2: address }),
                setState: (state: string) => set({ state }),
                setCity: (city: string) => set({ city }),
                setDay: (day: string) => set({ day }),
                setMonth: (month: string) => set({ month }),
                setYear: (year: string) => set({ year }),
                setGender: (gender: GenderType | null) => set({ gender }),
                getDateOfBirth: () => {
                    const state = get();
                    if (!state.day || !state.year || !state.month) return null;

                    return `${state.year}-${state.month.padStart(
                        2,
                        "0"
                    )}-${state.day.padStart(2, "0")}`;
                },
                setPsfp: (psfp: string) => {
                    set({ psfp });
                },
                setQuiz: (quiz: Quiz) => {
                    const state = get();
                    const quizzes = state.quizzes;
                    quizzes?.forEach((el) => {
                        if (el.question == quiz.question) {
                            el.answer = quiz.answer;
                        }
                    });
                    set({ quizzes });
                },
                setLeadId: (leadId: string | null) => set({ leadId }),
                setTrustedFormUrl: (url: string) =>
                    set({ trustedFormUrl: url }),
                setWebsiteConfig: (config: WebsiteConfig) => {
                    set({ websiteConfig: config });
                },
                setPathConfig: (config: PathConfig) => {
                    const path = config.path.map((p) => {
                        if (typeof p === "string") {
                            return { step: p } as StepConfig;
                        }
                        return p;
                    });
                    set({
                        pathConfig: config,
                        step: path[0],
                        path: path,
                        quizzes: path.find((el) => el.step == Step.Quiz)
                            ?.quizzes,
                    });
                },
                setOfferConfig: (config: OfferConfig) => {
                    set({
                        offerConfig: config,
                    });
                },
                setZeetoLoaded: (loaded: boolean) => {
                    set({ zeetoLoaded: loaded });
                },
                setLinkOuts: (linkOuts: LinkOut[]) => {
                    set({ linkOuts });
                },
                addMetadataKeys: (keys: string[]) => {
                    const state = get();
                    if (state.metadataKeys) {
                        set({
                            metadataKeys: [...state.metadataKeys, ...keys],
                        });
                    } else {
                        set({
                            metadataKeys: keys,
                        });
                    }
                },
                setDomainConfig: (config: DomainConfig) => {
                    set({ domainConfig: config });
                },
            }),
            {
                name: `path_user_store`,
                storage: createJSONStorage(() => localStorage),
                partialize: (state) => {
                    return Object.fromEntries(
                        Object.entries(state).filter(
                            ([key]) =>
                                ![
                                    "step",
                                    "path",
                                    "leadId",
                                    "rewardNonce",
                                    "trustedForm",
                                    "pathConfig",
                                    "offerConfig",
                                    "placementId",
                                    "websiteConfig",
                                    "linkOuts",
                                    "metadataKeys",
                                ].includes(key)
                        )
                    );
                },
            }
        )
    )
);

useUserState.subscribe(
    (state) => {
        return state.step;
    },
    (newValue, old) => {
        if (isRewardStep(newValue) && !startupParameters.ux.showReward) {
            // Don't track the step if the ux is configured to not show the reward, it will not be rendered anyway
            return;
        }
        if (!isPathStep(old) && isPathStep(newValue)) {
            trackUserProgress(Stage.OfferSelection);
        }
        if (isPathStep(old) && isRewardStep(newValue)) {
            trackUserProgress(Stage.RewardGame);
        }
        trackUserStep(newValue);
    },
    { equalityFn: shallow }
);

let trackUserSDPSData = true

export function stopTrackingUserSDPSData() {
    trackUserSDPSData = false
}

useUserState.subscribe(
    (state) => {
        return state;
    },
    async (newValue, old) => {
        if (
            newValue.step !== old.step ||
            newValue.metadataKeys !== old.metadataKeys
        ) {
            if (trackUserSDPSData) {
                await updateSDPSUser(newValue);
            }
        }
    },
    {
        equalityFn: shallow,
    }
);

export const isValidStateKey = (key: any, state: State): key is keyof State => {
    return key in state;
};

// TODO: move this to a more appropriate location, probably a middleware directory
let latestEmail = '';
useUserState.subscribe(async (state) => {
    await waitForSessionToken();
    const sessionToken = getTrackingSession()
    const baseUrl = state.websiteConfig?.pivotLinkOutUrl;
    const newEmail = state.email;
    if (sessionToken && baseUrl && newEmail && newEmail !== latestEmail) {
        latestEmail = newEmail;
        await trackUserIdentifier(latestEmail, latestPhone, state.psfp);
    }
});

let latestPhone = '';
useUserState.subscribe(async (state) => {
    await waitForSessionToken();
    const sessionToken = getTrackingSession()
    const baseUrl = state.websiteConfig?.pivotLinkOutUrl;
    const newPhone = state.phone;
    if (sessionToken && baseUrl && newPhone && newPhone !== latestPhone) {
        latestPhone = newPhone;
        await trackUserIdentifier(latestEmail, latestPhone, state.psfp);
    }
})

const trackUserIdentifier = async (email: string, phone: string, psfp: string|null) => {
    if (!email || !phone) {
        return;
    }
    await waitForSessionToken();
    await window.track(EventType.UserIdentifier, {
        email_md5: md5(email),
        phone_md5: md5(phone),
        psfp: psfp,
    });
}
