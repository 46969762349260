import { FormEvent, useState } from "react";
import { Step, useUserState } from "../state/user_state";
import React from "react";

import { ContinueButton } from "./continue_button/continue_button";
import "./input.css";
import { LeadId } from "./lead_id";
import { OffsetBackground } from "./offset_background";

export const ZipCode = () => {
    const userState = useUserState();
    const [zipCode, setZipCode] = useState(userState.zipCode || "");

    const submit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        userState.nextStep();
    };

    return (
        <form onSubmit={submit}>
            <div className="input-container">
                <h2 className="input-statement">
                    {userState.step.subHeader
                        ? userState.step.subHeader
                        : "Confirm your information below"}
                </h2>

                <label htmlFor="zipCode">Zipcode:</label>
                <input
                    autoFocus
                    value={zipCode}
                    required
                    minLength={5}
                    maxLength={5}
                    name="zipCode"
                    autoComplete="postal-code"
                    onChange={(e) => setZipCode(e.target.value)}
                ></input>
                <ContinueButton
                    type="submit"
                    text={userState.step.buttonText}
                    onClick={() => userState.setZipCode(zipCode)}
                />
            </div>
            <OffsetBackground topSpacing="4rem" />
        </form>
    );
};
