import { FormEvent, useState } from "react";
import { Step, useUserState } from "../state/user_state";
import React from "react";

import { ContinueButton } from "./continue_button/continue_button";
import "./input.css";
import "./gender.css";
import { GenderType } from "../models/gender";
import { OffsetBackground } from "./offset_background";

export const Gender = () => {
    const userState = useUserState();
    const [gender, setGender] = useState<GenderType | null>(userState.gender);

    const submit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        userState.setGender(gender);
        userState.nextStep();
    };

    return (
        <form onSubmit={submit}>
            <div className="input-container">
                <h2 className="input-statement">
                    {userState.step.subHeader ??
                        "Pick a gender below to personalize your free offers!"}
                </h2>

                <label htmlFor="firstName">Gender:</label>
                <label>
                    <input
                        type="radio"
                        required
                        value={"m"}
                        name="gender"
                        className="mr-4"
                        checked={gender === "M"}
                        onChange={(e) => setGender("M")}
                    ></input>
                    Male
                </label>
                <label>
                    <input
                        type="radio"
                        required
                        value={"f"}
                        name="gender"
                        className="mr-4"
                        checked={gender === "F"}
                        onChange={(e) => setGender("F")}
                    ></input>
                    Female
                </label>
                <ContinueButton
                    type="submit"
                    text={userState.step.buttonText ?? "Continue to play!"}
                    onClick={() => {
                        userState.setGender(gender);
                    }}
                />
            </div>
            <OffsetBackground topSpacing="4rem" />
        </form>
    );
};
