import "./button.css";
import styles from "./theme.module.css";
import { getThemeClass } from "../../helpers/theme_helper";

export const ContinueButton = ({
    type,
    onClick,
    text,
    size,
    usePathStyle,
}: {
    type: "submit" | "button";
    onClick: () => void;
    text?: string | React.ReactNode;
    size?: "small" | "large";
    usePathStyle?: boolean;
}) => {
    const extraClassName = size === "small" ? "small-button" : "";
    const themeClass = getThemeClass("continue-button", styles);
    const pathStyleOverride = usePathStyle ? "path-style" : "";
    return (
        <button
            type={type}
            className={`continue-button ${extraClassName} ${themeClass} ${pathStyleOverride}`}
            onClick={onClick}
        >
            {text ?? "Submit"}
        </button>
    );
};
