import React, { useState } from "react";
import { useUserState } from "../state/user_state";

export const US_STATES = [
    "",
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DC",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "PR",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
];

export const State = ({
    value,
    onChange,
    className,
}: {
    value: string;
    onChange: (value: string) => void;
    className?: string;
}) => {
    return (
        <select
            name="state"
            required
            className={className}
            value={value}
            autoComplete="address-level1"
            onChange={(e) => onChange(e.target.value)}
        >
            {US_STATES.map((s) => {
                return (
                    <option key={s} value={s}>
                        {s}
                    </option>
                );
            })}
        </select>
    );
};
