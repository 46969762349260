import { getThemeClass } from "../../helpers/theme_helper";
import { Theme, useTheme } from "../../state/theme";
import { Step, useUserState } from "../../state/user_state";
import styles from "./theme.module.css";

const textFormatForTheme = (name: string) => {
    const theme = useTheme();
    if (theme == Theme.SWEEPS) {
        return `${name}, Congrats!`;
    }
    return `Last Step ${name}!`;
};

export const CongratsMessage = () => {
    const state = useUserState();

    if (
        !state.firstName ||
        (state.placementId !== state.pathConfig?.secondaryPlacement &&
            state.step.step !== Step.LinkOut)
    ) {
        return null;
    }

    return (
        <div>
            <p
                className={`reward-message-heading ${getThemeClass(
                    "reward-heading",
                    styles
                )}`}
            >
                {textFormatForTheme(state.firstName)}
            </p>
            <p
                className={`reward-message-sub-heading ${getThemeClass(
                    "reward-subheading",
                    styles
                )}`}
            >
                {state.offerConfig?.secondaryPlacementText ??
                    "Claim your offers below, then proceed to complete your entry"}
            </p>
        </div>
    );
};
