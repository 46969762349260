import { useEffect, useRef, useState } from "react";
import { useUserState } from "../../state/user_state";
import { trackOfferWall } from "../../tracking/offer_wall";
import { EventType } from "@pushnami/models-pivot-tracking/dist/models";
import {startupParameters} from "../../state/startup";

const OFFERWALL_DEFAULT_TIMEOUT = 7000
const OFFERWALL_TIMEOUT_OVERRIDE = process.env.ADLOGIK_OFFERWALL_TIMEOUT ?? ""

/**
 * This hook watches the page for dom changes to be able to properly show a 
 * loader without it looking extremely choppy.
 */
export const useWatchOfferWallRender = () => {
    const { email, firstName, zipCode, gender, nextStep } = useUserState(({ email, firstName, gender, zipCode, nextStep }) => ({ email, firstName, gender, zipCode, nextStep }))
    const { utm_source, utm_content, utm_term } = startupParameters.publisher;
    const { setLoading, loadingState, loadingRef } = useLoading();

    const offerWallRef = useRef<Node>(null)

    useEffect(() => {
        // Options for the observer (which mutations to observe)
        const config = { childList: true, subtree: true, attributes: true };

        // Callback function to execute when mutations are observed
        const callback = (mutationList: MutationRecord[], cbObserver: MutationObserver) => {
            for (const mutation of mutationList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
                    setLoading(false)
                    cbObserver.disconnect()
                }
            }
        };

        const observer = new MutationObserver(callback);

        if (offerWallRef.current) {

            observer.observe(offerWallRef.current, config);
            const offerwallTimeout = getOfferwallTimout()
            setTimeout(() => {
                if (loadingRef.current === true) {
                    trackOfferWall(
                        EventType.OfferWallTimedOut,
                        { utm_source, utm_content, utm_term, email, zipCode }
                    )
                    observer.disconnect()
                    nextStep()
                }
            }, offerwallTimeout)
        }

        return () => observer.disconnect()

    }, [offerWallRef.current])

    return { ref: offerWallRef, loading: loadingRef.current, loadingState, utm_source, utm_content, utm_term, email, firstName, zipCode, gender }
}

const useLoading = () => {
    const loadingRef = useRef(true)
    const [loadingLocal, setLoadingLocal] = useState(true)

    const setLoading = (state: boolean) => {
        loadingRef.current = state
        setLoadingLocal(state);
    }

    return { setLoading, loadingRef, loadingState: loadingLocal }
}

const getOfferwallTimout = () => {
    const offerwallTimout = parseInt(OFFERWALL_TIMEOUT_OVERRIDE, 10)
    if (!Number.isNaN(offerwallTimout)) {
        return offerwallTimout
    }
    return OFFERWALL_DEFAULT_TIMEOUT
}