import { getThemeClass } from "../../helpers/theme_helper";
import { getDaysInMonth, months, years } from "../date_of_birth";

import styles from "./theme.module.css";

interface Props {
    month: string;
    day: string;
    year: string;
    setMonth: (val: string) => void;
    setDay: (val: string) => void;
    setYear: (val: string) => void;
}

export const DobForm = ({
    month,
    day,
    year,
    setMonth,
    setDay,
    setYear,
}: Props) => {
    const daysPerMonth = getDaysInMonth(year ? parseInt(year) : years[0]);
    const days =
        months.indexOf(month) != -1 ? daysPerMonth[months.indexOf(month)] : 31;
    return (
        <div className="date-of-birth-container">
            <select
                required
                className={getThemeClass("input", styles)}
                onChange={(e) => setMonth(e.currentTarget.value)}
                value={month ? parseInt(month) : ""}
                autoComplete="bday-month"
                name="month"
            >
                <option value="" disabled>
                    MM
                </option>
                {months.map((m) => {
                    const monthNumber = months.indexOf(m) + 1;
                    return (
                        <option key={m} value={monthNumber}>
                            {m}
                        </option>
                    );
                })}
            </select>
            <select
                required
                className={getThemeClass("input", styles)}
                autoComplete="bday-day"
                value={day ? day : ""}
                onChange={(e) => setDay(e.currentTarget.value)}
                name="day"
            >
                <option value="" disabled>
                    Day
                </option>
                {Array.from({ length: days }, (_, index) => index + 1).map(
                    (v) => {
                        return (
                            <option key={v} value={`${v}`}>
                                {v}
                            </option>
                        );
                    }
                )}
            </select>
            <select
                required
                autoComplete="bday-year"
                className={getThemeClass("input", styles)}
                value={year}
                onChange={(e) => setYear(e.currentTarget.value)}
                name="year"
            >
                <option value="" disabled>
                    YYYY
                </option>
                {years.map((y) => {
                    return (
                        <option key={y} value={`${y}`}>
                            {y}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};
