import { getThemeClass } from "../helpers/theme_helper";
import { Neustar } from "./neustar";

export const EmailInput = ({
    autofocus = false,
    styles,
    email,
    setEmail,
}: {
    autofocus?: boolean;
    styles: unknown;
    email: string;
    setEmail: (email: string) => void;
}) => {
    return (
        <div>
            <input
                autoFocus={autofocus}
                required
                type="email"
                value={email}
                name="email"
                placeholder="Enter Email Address"
                className={getThemeClass("input", styles)}
                autoComplete="email"
                onChange={(e) => {
                    setEmail(e.target.value);
                }}
                style={{ width: "100%" }}
            ></input>
            <Neustar />
        </div>
    );
};
