import "./win.css";
import "../reward_image/image.css";
import {ContinueButton} from "../continue_button/continue_button";
import {useEffect, useState} from "react";
import {useUserState} from "../../state/user_state";
import {startupParameters} from "../../state/startup";

export const WinView = (config: {
    onComplete: () => unknown;
}) => {
    const [visible, setVisible] = useState(false);
    const state = useUserState();

    useEffect(() => {
        setVisible(true);
    }, []);
    return (
        <div className={`win-absolute-fill  ${visible && "visible"}`}>
            <div className={`win-card ${visible && "visible"}`}>
                <p className="win-text win-thank-you">Congratulations!</p>
                <p className="win-text">
                    You are the big winner! Check the email you provided to
                    claim your reward!
                </p>
                <img
                    src={`${startupParameters.cdn.origin}${state.offerConfig?.rewardImageUri || ''}`}
                    className="img-responsive"
                />
                <div className="win-button">
                    <ContinueButton
                        onClick={config.onComplete}
                        size="small"
                        type={"button"}
                        text="Continue"
                    />
                </div>
            </div>
        </div>
    );
};
