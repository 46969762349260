import React, { useEffect, useRef, useState } from "react";
import { Step, useUserState } from "../../state/user_state";
import {startupParameters} from "../../state/startup";

let loadedScript = false;

export const ZeetoPlacement = () => {
    const state = useUserState();
    const [placement, setPlacement] = useState("");
    const publisherParams = startupParameters.publisher;

    useEffect(() => {
        if (state.pathConfig && !placement) {
            let placementId: string | undefined =
                state.pathConfig.primaryPlacement;
            if (state.step.step == Step.PathSecondary) {
                placementId = state.pathConfig.secondaryPlacement;
            }
            if (placementId && (!state.linkOuts || state.linkOuts.length === 0)) {
                setPlacement(placementId);
                state.setPlacement(placementId);
            } else {
                state.nextStep();
            }
        }
    }, [state.step.step, state.pathConfig, placement]);

    useEffect(() => {
        if ((state.zeetoLoaded || loadedScript) && placement) {
            (window as any).zan.push({
                name: "zeeto.init",
                data: {
                    visitorAttributes: {
                        firstName: state.firstName,
                        lastName: state.lastName,
                        email: state.email,
                        address: state.address1,
                        address2: state.address2 || null,
                        city: state.city,
                        state: state.state,
                        zipCode: state.zipCode,
                        phone: state.phone,
                        gender: state.gender,
                        dob: state.getDateOfBirth(),
                        country: "USA",
                    },
                    publisherAttributes: {
                        ...publisherParams,
                    },
                    placementId: placement,
                },
            });
            (window as any).fbq("track", "CompleteRegistration");
            (window as any).zan.on("zeeto.placementEnd", function (data: any) {
                const nextStep = state.getNextStep();
                // Next step would be reward & we have not shown secondary placement, show the placement
                if (
                    (nextStep?.step === Step.Reward || !nextStep) &&
                    state.pathConfig?.secondaryPlacement &&
                    placement !== state.pathConfig?.secondaryPlacement
                ) {
                    setPlacement(state.pathConfig?.secondaryPlacement);
                    state.setPlacement(state.pathConfig?.secondaryPlacement);
                } else if (nextStep?.step === Step.Reward || !nextStep) {
                    // next step is reward
                    state.setPlacement(null);
                    state.goToReward();
                } else if (nextStep?.step === Step.PathSecondary) {
                    setPlacement("");
                    state.setPlacement(
                        state.pathConfig?.secondaryPlacement || null
                    );
                    state.nextStep();
                } else {
                    setPlacement("");
                    state.setPlacement(null);
                    state.nextStep();
                }
            });
        }
    }, [state.zeetoLoaded, placement, setPlacement]);
    return null;
};
