import { Path } from "../../path";
import { Step, useUserState} from "../../state/user_state";
import {useOfferConfig} from "../../state/offer_config";
import {usePathConfig} from "../../state/path_config";
import {useMemo} from "react";
import {AlmostThere} from "../../components/path_incentives/almost_there";
import {CongratsMessage} from "../../components/path_incentives/congrats_message";
import {startupParameters} from "../../state/startup";

export const LotteryLayout = (config: {
    onComplete: () => unknown;
}) => {
    const offerConfig = useOfferConfig();
    const path = usePathConfig();
    const state = useUserState();
    const currentStep = useMemo(() => {
        const result = path?.path.findIndex(step => step === state.step || step === state.step?.step);
        return result === -1 ? 0 : result;
    }, [path, state])
    const stepHidesRewardByDefault = useMemo(() => [Step.OfferWall, Step.Reward, Step.Loading, Step.LinkOut].includes(state.step?.step || state.step), [state.step]);
    const stepHidesProgressByDefault = useMemo(() => [Step.OfferWall, Step.Reward, Step.Unlock, Step.UnlockSamples, Step.Loading, Step.AutoRegConfirmation, Step.ThankYou, Step.LinkOut].includes(state.step?.step || state.step), [state.step]);
    const isInOfferStep = useMemo(() => state.step?.step === Step.LinkOut, [state.step]);
    return <>
        {isInOfferStep && (
            <AlmostThere />
        )}
        {isInOfferStep && (
            <CongratsMessage />
        )}
        {(state.step.showReward || (state.step.showReward ?? !stepHidesRewardByDefault)) && (
            <>
                <h1 className="layout-offer-header">{offerConfig?.rewardText}</h1>
                <img
                src={`${startupParameters.cdn.origin}${offerConfig?.rewardImageUri || ''}`}
                alt={offerConfig?.rewardImageAlt || "Offer image"}
                loading="lazy"
                className="layout-offer-image"
                ></img>
            </>
        )}
        {state.step?.subHeader && (
            <h2 className="sub-header">
                {state.step?.subHeader ?? "Almost there!"}
            </h2>
        )}
        {(state.step.showProgress || ( state.step.showProgress ?? !stepHidesProgressByDefault)) && (
            <label className="layout-progress-label">
                Progress to {offerConfig?.promoAmount || "your reward"}
                <progress max={path?.path.length || 1} value={currentStep}></progress>
            </label>
        )}
        <Path onComplete={config.onComplete}/>
    </>;
};
