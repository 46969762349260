import { convertDateFormat } from "../helpers/date_helper";
import { convertKeysToAttributes } from "../helpers/metadata_keys_helper";
import { State } from "../state/user_state";

interface SMSOptIn {
    optin: boolean;
    modifyDate: string;
}

interface QA {
    key: string;
    answer: string | null;
}

export interface PhonePayload {
    key: string; // websiteApiKey
    phone: string | null;
    registrationUrl: string;
    timestamp: string;
    useragent: string;
    subscriberVariables: {
        firstname?: string | null;
        lastname?: string | null;
        address?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        dob?: string | null;
        dobyear?: string | null;
        dobmonth?: string | null;
        dobday?: string | null;
        gender?: string | null;
        zip?: string | null;
        email?: string | null;
        trustedform?: string | null;
        form_id?: string | null;
        mbrefprod?: string | null;
        refprod?: string | null;
        optin?: Record<string, SMSOptIn>;
        quizzes?: QA[];
    };
    autoInject: Exclude<keyof PhonePayload | 'ip', 'subscriberVariables' | 'autoInject'>[]
}

export interface EmailPayload {
    key: string | null; // websiteApiKey

    fname?: string | null;
    lname?: string | null;
    email: string | null;
    phone?: string | null;

    dob?: string | null;
    dobyear?: string | null;
    dobmonth?: string | null;
    dobday?: string | null;

    gender?: string | null;

    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;

    registrationUrl: string | null;
    timestamp: string | null;

    placement: string | null;
    device: string | null; // mobile/desktop

    autoInject: Exclude<keyof EmailPayload | 'ip' | 'useragent', 'autoInject'>[]
}

const deviceType = () => {
    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent || navigator.vendor || (window as any).opera
        )
    ) {
        return "MOBILE";
    } else {
        return "DESKTOP";
    }
};

export const SDPS = {
    buildEmailPayload: (
        state: State,
        websiteApiKey: string,
        placement: string
    ): EmailPayload => {
        const dob = convertDateFormat(
            `${state.month}-${state.day}-${state.year}`
        );

        const payload: EmailPayload = {
            key: websiteApiKey,
            email: state.email,
            registrationUrl: "try.prizestash.com",
            timestamp: new Date().toISOString(),
            placement,
            device: deviceType()            ,
            autoInject: ['ip', 'useragent'],
        };

        if (state.firstName) payload.fname = state.firstName;
        if (state.lastName) payload.lname = state.lastName;
        if (state.phone) payload.phone = state.phone;
        if (dob?.day) payload.dobday = dob.day;
        if (dob?.month) payload.dobmonth = dob.month;
        if (dob?.year) payload.dobyear = dob.year;
        if (dob?.year && dob?.month && dob?.year)
            payload.dob = `${dob.month}-${dob.day}-${dob.year}`;
        if (state.gender) payload.gender = state.gender;
        if (state.address1) payload.address1 = state.address1;
        if (state.address2) payload.address2 = state.address2;
        if (state.city) payload.city = state.city;
        if (state.state) payload.state = state.state;
        if (state.zipCode) payload.zip = state.zipCode;

        return payload;
    },

    buildSmsPayload: (
        state: State,
        websiteApiKey: string,
        smsOptInKey: string
    ): PhonePayload => {
        const dob = convertDateFormat(
            `${state.month}-${state.day}-${state.year}`
        );

        const payload: PhonePayload = {
            key: websiteApiKey,
            phone: state.phone,
            registrationUrl: "try.prizestash.com",
            timestamp: new Date().toISOString(),
            useragent: navigator.userAgent,
            subscriberVariables: {
                mbrefprod: "",
                refprod: "",
                optin: {
                    [smsOptInKey]: {
                        optin: state.phoneAllowed,
                        modifyDate: new Date().toISOString(),
                    },
                },
            },
            autoInject: ['ip', 'useragent'],
        };

        if (state.firstName)
            payload.subscriberVariables.firstname = state.firstName;
        if (state.lastName)
            payload.subscriberVariables.lastname = state.lastName;
        if (dob?.day) payload.subscriberVariables.dobday = dob.day;
        if (dob?.month) payload.subscriberVariables.dobmonth = dob.month;
        if (dob?.year) payload.subscriberVariables.dobyear = dob.year;
        if (dob?.year && dob?.month && dob?.year)
            payload.subscriberVariables.dob = `${dob.month}-${dob.day}-${dob.year}`;
        if (state.gender) payload.subscriberVariables.gender = state.gender;
        if (state.address1)
            payload.subscriberVariables.address = state.address1;
        if (state.address2)
            payload.subscriberVariables.address2 = state.address2;
        if (state.city) payload.subscriberVariables.city = state.city;
        if (state.state) payload.subscriberVariables.state = state.state;
        if (state.zipCode) payload.subscriberVariables.zip = state.zipCode;
        if (state.email) payload.subscriberVariables.email = state.email;
        if (state.trustedFormUrl)
            payload.subscriberVariables.trustedform = state.trustedFormUrl;
        if (state.leadId) payload.subscriberVariables.form_id = state.leadId;
        if (state.email) payload.subscriberVariables.email = state.email;
        if (state.quizzes)
            payload.subscriberVariables.quizzes = state.quizzes.map((el) => ({
                key: el.key,
                answer: el.answer,
            }));
        if (state.metadataKeys)
            payload.subscriberVariables = {
                ...payload.subscriberVariables,
                ...convertKeysToAttributes(state.metadataKeys),
            };

        return payload;
    },
    emailSubscribe: async (payload: EmailPayload, baseUrl?: string) => {
        if (!baseUrl) {
            return;
        }
        await fetch(`${baseUrl}/api/email/subscribe`, {
            method: "POST",
            body: JSON.stringify(payload),
        });
    },
    smsSubscribe: async (payload: PhonePayload, baseUrl?: string) => {
        if (!baseUrl) {
            return;
        }
        await fetch(`${baseUrl}/api/sms/subscribe`, {
            method: "POST",
            body: JSON.stringify(payload),
        });
    },
};
