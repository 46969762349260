import {Step, stopTrackingUserSDPSData, useUserState} from "../../state/user_state";
import {shallow} from "zustand/shallow";
import {StepConfig} from "../../models/step_config";
import {isPathStep, isRewardStep} from "../../helpers/steps";
import {pauseTracking, trackUserBlocks} from "../../tracking/user";

export async function handleBlockRequest(reason: string) {
    stopTrackingUserSDPSData();
    await trackUserBlocks(reason);
    pauseTracking();
    redirectToThankYouOnGameOrRewards();
}

function redirectToThankYouOnGameOrRewards() {
    useUserState.subscribe(
        (state) => {
            return state.step;
        },
        (newValue, old) => {
            if (isGameOrRewardStep(newValue, old)) {
                redirectToThankYou();
            }
        },
        {equalityFn: shallow}
    );
}


function isGameOrRewardStep(newValue: StepConfig, old: StepConfig): boolean {
    const isOfferSelectionStage = !isPathStep(old) && isPathStep(newValue);
    const isRewardGameStage = isPathStep(old) && isRewardStep(newValue);
    return isOfferSelectionStage || isRewardGameStage;
}

function redirectToThankYou() {
    const thankYouStep: StepConfig = {
        step: Step.ThankYou,
    }
    useUserState.setState({path: [thankYouStep], step: thankYouStep});
}