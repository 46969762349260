import { useOfferConfig } from "../../state/offer_config";
import { Step, useUserState } from "../../state/user_state";

export const TopMessage = () => {
    const { step } = useUserState();
    const offerConfig = useOfferConfig();

    if (
        step.step === Step.Path ||
        step.step === Step.Reward ||
        step.step === Step.PathSecondary ||
        step.step === Step.LinkOut ||
        step.showReward === false
    ) {
        return null;
    }

    return (
        <div>
            <p className="mt-4 text-center text-green-800 text-3xl md:text-4xl lg:text-6xl font-bold">
                {step.header ?? offerConfig?.rewardText}
            </p>
            {step.step === Step.Unlock ? (
                <p className="text-center mt-4 text-lg font-bold md:text-xl reward-sub-heading">
                    {step.subHeader ?? "Unlock your FREE play!"}
                </p>
            ) : null}
        </div>
    );
};
