import { useEffect } from "react";
import {useUserState} from "./user_state";
import {startupParameters} from "./startup";

let loading = false;

const setTitle = (title?: string) => {
    if (!document.title && title) document.title = title;
};

export const useWebsiteConfig = () => {
    const { setWebsiteConfig, websiteConfig } = useUserState((s) => {
        return {
            setWebsiteConfig: s.setWebsiteConfig,
            websiteConfig: s.websiteConfig,
        };
    });
    useEffect(() => {
        if (loading || websiteConfig) {
            setTitle(websiteConfig?.siteName);
            return;
        }
        loading = true;
        const fetchConfig = async () => {
            try {
                const response = await fetch(`${startupParameters.cdn.manifest}/manifest.json`);
                const data = await response.json();
                setTitle(data?.siteName);
                setWebsiteConfig(data);
            } catch (error) {
                console.error("Failed to fetch config:", error);
            }
        };

        fetchConfig();
    }, []);
};
