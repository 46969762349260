import "./soi_creative.css";
import DOMPurify from "dompurify";
import { useState } from "react";
import { LeadId } from "../lead_id";
import { SingleOptInCreative, Answer } from "../../models/soi";
import { useUserState } from "../../state/user_state";
import {
    trackOfferClick,
    trackOfferSkip,
    trackOfferTcpa,
} from "../../tracking/offers";
import { deliverLead } from "../../api/delivery";
import { getThemeClass } from "../../helpers/theme_helper";
import styles from "./soi_creative.module.css";
import {startupParameters} from "../../state/startup";

interface Props {
    creative: SingleOptInCreative;
    nextCreative: () => void;
    usePathStyle?: boolean;
    explore: boolean;
    alg: string;
}

const nowDate = new Date();

export const SingleOptInCreativeComponent = ({
    creative,
    nextCreative,
    usePathStyle,
    explore,
    alg,
}: Props) => {
    const [leadId, setLeadId] = useState("");
    const userState = useUserState();
    const pubParams = startupParameters.publisher;
    const themeClass = getThemeClass("soi-answer-button", styles);
    const handleClick = (answer: Answer) => {
        // Move to the next creative
        nextCreative();
        trackOfferClick(
            creative.offer_id,
            creative.id,
            answer.metadata_keys?.map((k) => k.sort_key),
            explore,
            alg
        );
        trackOfferTcpa(creative.offer_id, leadId);
        if (answer.deliver_lead) {
            deliverLead(creative.offer_id, userState, leadId, pubParams);
        }

        // Update metadata based on provided keys from server
        if (answer.metadata_keys && answer.metadata_keys.length > 0) {
            userState.addMetadataKeys(
                answer.metadata_keys.map((k) => k.sort_key)
            );
        }
    };
    const pathStyleOverride = usePathStyle ? "path-style" : "";
    return (
        <div className="soi-container flex flex-col justify-center items-center  bg-white rounded-lg px-12 py-8">
            <div className="soi-ad-text-container flex justify-around w-full pb-4">
                <span className="text-sm soi-ad-notify">Sponsored Ad</span>
                <span className="text-sm soi-ad-notify">
                    Question Not Required
                </span>
            </div>
            <div>
                <h2
                    className="soi-header text-center lg:text-2xl md:text-xl sm:text-lg font-bold mb-4"
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(creative.header),
                    }}
                />
            </div>
            <div className="soi-answer-container flex flex-col gap-2 md:w-[80%] w-[90%]">
                {creative.answers.map((a) => {
                    const styles = a.deliver_lead
                        ? `soi-answer-button px-12 py-3 rounded-2xl text-white font-bold transition hover:scale-110 ${pathStyleOverride} ${themeClass}`
                        : `soi-answer-button soi-no-deliver-button bg-gray-200 border border-gray-300 px-12 py-3 rounded-2xl text-white transition font-bold hover:scale-90`;
                    return (
                        <button
                            key={a.text}
                            className={styles}
                            onClick={() => handleClick(a)}
                        >
                            <span className="drop-shadow-md">{a.text}</span>
                        </button>
                    );
                })}
            </div>
            <div className="text-xs text-center mt-8">
                <label className="text-xs text-center soi-tcpa-compliance">
                    <input type="hidden" id="leadid_tcpa_disclosure" />
                    <span
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                                creative.tcpa.replace(
                                    /{now}/g,
                                    nowDate.toLocaleString()
                                )
                            ),
                        }}
                    />
                </label>
            </div>
            <LeadId setLeadId={setLeadId} name={creative.id} />
        </div>
    );
};
