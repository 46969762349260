import { GenderType } from "../../models/gender";
import { FaMale, FaFemale } from "react-icons/fa";

export const GenderIconButtons = ({
    gender,
    setGender,
}: {
    gender: GenderType | null;
    setGender: (gender: GenderType) => void;
}) => {
    return (
        <div className="flex justify-between gap-4">
            <div className="flex-1">
                <input
                    type="radio"
                    required
                    value={"m"}
                    name="gender"
                    id="male-radio"
                    className="hidden peer"
                    checked={gender === "M"}
                    onChange={(e) => {
                        setGender("M");
                    }}
                ></input>
                <label
                    htmlFor="male-radio"
                    className="inline-flex items-center justify-between w-full p-3 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100"
                >
                    <FaMale /> Male
                </label>
            </div>
            <div className="flex-1">
                <input
                    type="radio"
                    required
                    value={"f"}
                    id="female-radio"
                    name="gender"
                    className="hidden peer"
                    checked={gender === "F"}
                    onChange={(e) => setGender("F")}
                ></input>
                <label
                    htmlFor="female-radio"
                    className="inline-flex items-center justify-between w-full p-3 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100"
                >
                    <FaFemale /> Female
                </label>
            </div>
        </div>
    );
};
