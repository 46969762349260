import { useEffect } from "react"
import { offerWallDivId } from "./offerWallScript"
import type React from "react"

/**
 * This hook loads the offerwall from offerwallscript placeholder div
 * and places it into the offerwall component.
 */
export const useOfferwallPlacement = (offerwallContainerRef: React.RefObject<Node>) => {
    useEffect(() => {
        if (!offerwallContainerRef.current) return;

        const offerWallElement = document.getElementById(offerWallDivId) as HTMLDivElement;
        const parentElement = offerwallContainerRef.current;
        if (offerWallElement && parentElement) {

            requestAnimationFrame(() => {
                parentElement.appendChild(offerWallElement);

                setTimeout(() => {
                    offerWallElement.style.display = 'block';
                }, 500)
            });
        }

        return () => {
            if (offerWallElement && parentElement?.contains(offerWallElement)) {
                parentElement.removeChild(offerWallElement);
            }
        }


    }, [offerwallContainerRef])
}