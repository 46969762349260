import { useUserState } from "../../state/user_state";
import { PhoneTcpaDefaultContent } from "./phone_tcpa_default_content";
import { useMemo } from "react";

export const PhoneTcpa = ({
                              phoneAllowed,
                              setPhoneAllowed,
                              submitText,
                          }: {
    phoneAllowed: boolean;
    setPhoneAllowed: (value: boolean) => void;
    submitText?: string;
}) => {
    const { websiteConfig, tcpa } = useUserState((s) => ({
        websiteConfig: s.websiteConfig,
        tcpa: s.domainConfig?.tcpa?.phone
    }));
    const cleanTcpa = useMemo(() => {
        if (!tcpa) {
            return ""
        }
        const parser = new DOMParser();
        const doc = parser.parseFromString(tcpa, "text/html");
        const text = doc.body.textContent?.trim();
        return text ? tcpa : ""
    }, [tcpa]);
    return (<label className="disclaimer-phone mt-2">
            <input
                type="checkbox"
                name="phoneAllow"
                className="mr-2"
                autoComplete="tel"
                checked={phoneAllowed}
                onChange={() => setPhoneAllowed(!phoneAllowed)}
            />
            {cleanTcpa ? <p dangerouslySetInnerHTML={{ __html: cleanTcpa }}/> : <PhoneTcpaDefaultContent submitText={submitText} siteBaseUrl={websiteConfig?.siteBaseUrl ?? "/"} />}
    </label>)
};
