import { State } from "../state/user_state";
import {startupParameters} from "../state/startup";

export const buildEndUrl = (endUrl: string | undefined, userData: State) => {
    if (endUrl) {
        return `${endUrl}?email=${userData.email}&firstname=${
            userData.firstName
        }&zip=${userData.zipCode}&subaff1=${startupParameters.publisher.utm_source}&gender=${userData.gender}`;
    }
    return "//prizestash.com";
};

export const endPlacement = (endUrl: string | undefined, userData: State) => {
    window.location.href = buildEndUrl(endUrl, userData);
};
