import { ContinueButton } from "../continue_button/continue_button";
import { FaAngleDoubleRight } from "react-icons/fa";
import { LinkOutViewComponentProps } from "./linkout";
import { trackOfferClick } from "../../tracking/offers";
import { Theme, useTheme } from "../../state/theme";

export const LinkOutTextCreative = ({
    currentLinkOut,
    onContinue,
    onSkip,
}: LinkOutViewComponentProps) => {
    const isLgBtn = useTheme() === Theme.LARGE_BUTTON;
    const skipButton = isLgBtn ? "No" : currentLinkOut.linkout_creative?.creative.skip_button || ">>>";

    return (
        <div className="bg-white p-4 rounded-md min-h-96 my-auto">
            <h2 className="font-bold text-center text-4xl md:text-5xl">
                {currentLinkOut.linkout_creative?.creative.header}
            </h2>
            <h3 className="text-center text-3xl">
                {currentLinkOut.linkout_creative?.creative.sub_header}
            </h3>
            <div className="max-w-md mx-auto flex justify-center">
                <ContinueButton
                    usePathStyle={true}
                    type={"button"}
                    text={
                        <span className="flex my-auto justify-center align-middle">
                            {currentLinkOut.linkout_creative?.creative
                                .submit_button || "Redeem my offer"}
                            <FaAngleDoubleRight className="my-auto ml-2" />
                        </span>
                    }
                    onClick={() => {
                        window.open(
                            currentLinkOut.linkout_creative?.link.landing_url,
                            "_blank"
                        );
                        trackOfferClick(
                            currentLinkOut.offer_id,
                            currentLinkOut.linkout_creative?.creative.id || "",
                            [],
                            currentLinkOut.explore,
                            currentLinkOut.alg
                        );
                        onContinue();
                    }}
                />
            </div>
            <div className={`${isLgBtn ? 'max-w-md m-auto opacity-65' : 'my-12'} justify-center flex text-xs text-gray-300 sans`}>
                <button
                    className={`${isLgBtn ? 'continue-button' : 'bg-transparent hover:bg-transparent hover:underline'} path-style bg-transparent cursor-default`}
                    onClick={onSkip}
                >
                    { skipButton }
                </button>
            </div>
        </div>
    );
};
