import type {StartupParameters} from "../models/startup";
import {GenderType} from "../models/gender";

const loadCdn = (embedded: boolean) => {
    let origin = window.location.origin || "";
    let script = `${origin}/pivot.min.js`;
    let styles = `${origin}/pivot.min.css`;
    let dir = origin;
    let manifest = origin;

    
    const scriptSrc = document.currentScript as HTMLScriptElement|null;
    if (scriptSrc?.src) {
        origin = new URL(scriptSrc.src).origin ?? "";
        if (origin.endsWith("/")) {
            origin = origin.slice(0, -1);
        }
        dir = scriptSrc.src.split("/").slice(0, -1).join("/");
        styles = `${dir}/${scriptSrc.src.split("/").pop()?.replace(".js", ".css") || "pivot.min.css"}`;
        if (embedded) {
            manifest = origin + '/config'
        }
    }

    return {
        origin,
        script,
        dir,
        styles,
        manifest,
    }
}

const data = {
    path: {
        rewardId: "null",
        variant: "default",
    },
    ux: {
        theme: "pivot",
        autoRegister: false,
        showReward: true,
        logo: "",
    },
    user: {
        firstName: null as string | null,
        lastName: null as string | null,
        zipCode: null as string | null,
        phone: null as string | null,
        email: null as string | null,
        address1: null as string | null,
        city: null as string | null,
        state: null as string | null,
        day: null as string | null,
        month: null as string | null,
        year: null as string | null,
        gender: null as GenderType | null,
    },
    publisher: {
        c1: null as string | null,
        c2: null as string | null,
        c3: null as string | null,
        c4: null as string | null,
        c5: null as string | null,
        utm_source: null as string | null,
        utm_campaign: null as string | null,
        utm_medium: null as string | null,
        utm_term: null as string | null,
        utm_content: null as string | null,
        ga_client_id: null as string | null,
        ga_user_id: null as string | null,
        publisher_visit_id: null as string | null,
        publisher_visitor_id: null as string | null,
        publisher_postback_unique_id: null as string | null,
        publisher_lead_id: null as string | null,
        click_id: null as string | null,
        origin_id: null as string | null,
        publisher_experiment_id: null as string | null,
        publisher_variation_id: null as string | null,
    },
    cdn: loadCdn(false),
}

const urlParams = new URLSearchParams(window.location.search);

export const startupParameters: StartupParameters = {
    get path() {
        return data.path;
    },
    get ux() {
        return data.ux;
    },
    get user() {
        return data.user;
    },
    get publisher() {
        return data.publisher;
    },
    get cdn() {
        return data.cdn;
    },
};

const loadPathFromUrl = () => {
    data.path.rewardId = getUrlParamValue("rwid", "");
    data.path.variant = getUrlParamValue("pnptest", "default");
}

const loadUxFromUrl = () => {
    data.ux.theme = getUrlParamValue("dply", "pivot");
    data.ux.autoRegister = getUrlParamValue("auto") === "true";
}

const loadUserFromUrl = () => {
    data.user.firstName = getUrlParamValueFromList(
        ["first_name", "fname", "firstname", "f_name", "first"],
        ""
    )
    data.user.lastName = getUrlParamValueFromList(
        ["last_name", "lname", "lastname", "family_name"],
    )
    data.user.zipCode = getUrlParamValueFromList(
        ["postal", "zip", "zip2", "zipcode"],
    )
    data.user.phone = getUrlParamValueFromList(
        ["phone", "mobile", "telephone"],
    )
    data.user.email = getUrlParamValue("email")
    data.user.address1 = getUrlParamValueFromList(
        ["street1", "address", "address1", "street"],
    )
    data.user.city = getUrlParamValue("city")
    data.user.state = getUrlParamValueFromList(
        ["state_initials", "state"],
    )?.toUpperCase() || ""
    const rawDob = getUrlParamValueFromList(
        ["date_of_birth", "birthdate", "dob", "dateofbirth", "birthday"],
        "",
    );
    const dobParts = rawDob.split("-");
    if (dobParts.length === 3) {
        data.user.day = dobParts[0];
        data.user.month = dobParts[1];
        data.user.year = dobParts[2];
    }
    data.user.gender = computeGender(getUrlParamValue("gender"))
}

const loadPublisherFromUrl = () => {
    data.publisher.c1 = getUrlParamValue("c1");
    data.publisher.c2 = getUrlParamValue("c2");
    data.publisher.c3 = getUrlParamValue("c3");
    data.publisher.c4 = getUrlParamValue("c4");
    data.publisher.c5 = getUrlParamValue("c5");
    data.publisher.utm_source = getUrlParamValue("utm_source");
    data.publisher.utm_campaign = getUrlParamValue("utm_campaign");
    data.publisher.utm_medium = getUrlParamValue("utm_medium");
    data.publisher.utm_term = getUrlParamValue("utm_term");
    data.publisher.utm_content = getUrlParamValue("utm_content");
    data.publisher.ga_client_id = getUrlParamValue("ga_client_id");
    data.publisher.ga_user_id = getUrlParamValue("ga_user_id");
    data.publisher.publisher_visit_id = getUrlParamValue("publisher_visit_id");
    data.publisher.publisher_visitor_id = getUrlParamValue("publisher_visitor_id");
    data.publisher.publisher_postback_unique_id = getUrlParamValue("publisher_postback_unique_id");
    data.publisher.publisher_lead_id = getUrlParamValue("publisher_lead_id");
    data.publisher.click_id = getUrlParamValue("click_id");
    data.publisher.origin_id = getUrlParamValue("origin_id");
    data.publisher.publisher_experiment_id = getUrlParamValue("publisher_experiment_id");
    data.publisher.publisher_variation_id = getUrlParamValue("publisher_variation_id");
}

export const loadFromUrl = () => {
    loadPathFromUrl();
    loadUxFromUrl();
    loadUserFromUrl();
    loadPublisherFromUrl();
}

function getUrlParamValue(key: string): string | null;
function getUrlParamValue(key: string, defaultValue: string): string;
function getUrlParamValue(key: string, defaultValue?: string) {
    return urlParams.get(key) || (defaultValue ?? null);
}

function getUrlParamValueFromList(keys: string[]): string | null;
function getUrlParamValueFromList(keys: string[], defaultValue: string): string;
function getUrlParamValueFromList(keys: string[], defaultValue?: string) {
    let value = null;
    for (const key of keys) {
        value = getUrlParamValue(key);
        if (value) {
            break;
        }
    }
    return value || (defaultValue ?? null);
}

export const loadFromAttributes = (component: HTMLElement) => {
    loadPathFromAttributes(component);
    loadUxFromAttributes(component);
    loadUserFromAttributes(component);
    loadPublisherFromAttributes(component);
    data.cdn = loadCdn(true)
}

const loadPathFromAttributes = (component: HTMLElement) => {
    data.path.rewardId = component.getAttribute("rwid") ?? "";
    data.path.variant = component.getAttribute("pnptest") ?? "default";
}

const loadUxFromAttributes = (component: HTMLElement) => {
    data.ux.theme = component.getAttribute("dply") ?? "pivot";
    data.ux.autoRegister = component.getAttribute("auto") === "true";
    data.ux.showReward = component.getAttribute("show-reward") === "true";
    data.ux.logo = component.getAttribute("logo") ?? "";
}

const loadUserFromAttributes = (component: HTMLElement) => {
    data.user.firstName = component.getAttribute("firstname");
    data.user.lastName = component.getAttribute("lastname");
    data.user.zipCode = component.getAttribute("zipcode");
    data.user.phone = component.getAttribute("phone");
    data.user.email = component.getAttribute("email");
    data.user.address1 = component.getAttribute("address1");
    data.user.city = component.getAttribute("city");
    data.user.state = component.getAttribute("state")?.toUpperCase() || ""
    const rawDob = component.getAttribute("dob") ?? "";
    const dobParts = rawDob.split("-");
    if (dobParts.length === 3) {
        data.user.day = dobParts[0];
        data.user.month = dobParts[1];
        data.user.year = dobParts[2];
    }
    data.user.gender = computeGender(component.getAttribute("gender"))
}

const loadPublisherFromAttributes = (component: HTMLElement) => {
    data.publisher.c1 = component.getAttribute("c1");
    data.publisher.c2 = component.getAttribute("c2");
    data.publisher.c3 = component.getAttribute("c3");
    data.publisher.c4 = component.getAttribute("c4");
    data.publisher.c5 = component.getAttribute("c5");
    data.publisher.utm_source = component.getAttribute("utm_source");
    data.publisher.utm_campaign = component.getAttribute("utm_campaign");
    data.publisher.utm_medium = component.getAttribute("utm_medium");
    data.publisher.utm_term = component.getAttribute("utm_term");
    data.publisher.utm_content = component.getAttribute("utm_content");
    data.publisher.ga_client_id = component.getAttribute("ga_client_id");
    data.publisher.ga_user_id = component.getAttribute("ga_user_id");
    data.publisher.publisher_visit_id = component.getAttribute("publisher_visit_id");
    data.publisher.publisher_visitor_id = component.getAttribute("publisher_visitor_id");
    data.publisher.publisher_postback_unique_id = component.getAttribute("publisher_postback_unique_id");
    data.publisher.publisher_lead_id = component.getAttribute("publisher_lead_id");
    data.publisher.click_id = component.getAttribute("click_id");
    data.publisher.origin_id = component.getAttribute("origin_id");
    data.publisher.publisher_experiment_id = component.getAttribute("publisher_experiment_id");
    data.publisher.publisher_variation_id = component.getAttribute("publisher_variation_id");
}

const computeGender = (value: string | null): GenderType | null => {
    switch ((value ?? "").toLowerCase()) {
        case "male":
        case "m":
        case "man":
        case "boy":
            return "M";
        case "female":
        case "f":
        case "woman":
        case "girl":
            return "F";
        default:
            return null;
    }
};
