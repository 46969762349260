import "./lose.css";
import {ContinueButton} from "../continue_button/continue_button";
import {useEffect, useState} from "react";
import {useUserState} from "../../state/user_state";
import {Stage, trackUserProgress} from "../../tracking/user";

let timeout: NodeJS.Timeout | null = null;
export const Lose = (config: {
    onComplete: () => unknown;
}) => {
    const [visible, setVisible] = useState(false);
    const state = useUserState();

    useEffect(() => {
        setVisible(true);
        if (timeout) return;

        trackUserProgress(Stage.EndOfPathRedirect);

        timeout = setTimeout(config.onComplete, 3000);
    }, [state.websiteConfig]);
    return (
        <div className={`lose-absolute-fill  ${visible && "visible"}`}>
            <div className={`lose-card ${visible && "visible"}`}>
                <p className="lose-text lose-thank-you">
                    Thank you for playing!
                </p>
                <p className="lose-text">
                    Sorry you aren't an instant winner today! Please come back
                    and try again, you have a chance to win everyday!
                </p>
                <div className="lose-button">
                    <ContinueButton
                        onClick={config.onComplete}
                        size="small"
                        type={"button"}
                        text="Continue"
                    />
                </div>
            </div>
        </div>
    );
};
