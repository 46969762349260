import { SingleOptInCreative } from "../models/soi";
import { LinkOutCreativePayload } from "../models/linkout";
import { Qualifier } from "../models/qualifier";

export interface LinkOut {
    offer_id: string;
    explore: boolean;
    alg: string;
    linkout_creative?: LinkOutCreativePayload;
    soi_creative?: SingleOptInCreative;
    qualifiers?: Qualifier[]; 
}

interface Payload {
    step: "linkout";
    user_data: {
        firstName: string;
        lastName: string;
        email: string;
        psfp: string;
        address: string;
        address2: string;
        city: string;
        state: string;
        zipCode: string;
        phone: string;
        gender: string;
        dob: string;
        country: string;
        session?: string;
        utm_params: {
            utm_source: string | null;
            click_id: string | null;
            [key: string]: string | null;
        };
    };
}

interface Response {
    offers: LinkOut[];
}

export const PivotApi = {
    getLinkOuts: async (url: string, payload: Payload, limit: number) => {
        const response = await fetch(url + "/path/next?limit=" + limit, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ ...payload }),
        });
        if (response.status === 200) {
            try {
                const body = (await response.json()) as Response;
                return body.offers;
            } catch (err) {}
        }
        return [];
    },
    check: async (apiUrl: string, email: string, sessionToken: string) => fetch(apiUrl + "/api/v1/check/" + email, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ session_token: sessionToken }),
    })
};
