import { FormEvent, useState } from "react";
import { Step, useUserState } from "../state/user_state";
import React from "react";

import { ContinueButton } from "./continue_button/continue_button";
import "./input.css";
import { State } from "./state";
import { OffsetBackground } from "./offset_background";

export const Address = () => {
    const userState = useUserState();
    const [address1, setAddress1] = useState(userState.address1 || "");
    const [address2, setAddress2] = useState(userState.address2 || "");
    const [city, setCity] = useState(userState.city || "");
    const [state, setState] = useState(userState.state || "");
    const submit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        userState.setAddress1(address1);
        userState.setAddress2(address2);
        userState.setCity(city);
        userState.setState(state);
        userState.nextStep();
    };

    return (
        <form onSubmit={submit}>
            <div className="input-container">
                <h2 className="input-statement">
                    {userState.step.subHeader ??
                        "Confirm your address to qualify."}
                </h2>

                <label htmlFor="address1">Street Address:</label>
                <input
                    autoFocus
                    required
                    value={address1}
                    name="address1"
                    autoComplete="address-line1"
                    onChange={(e) => setAddress1(e.target.value)}
                ></input>
                <label htmlFor="address2">Address 2:</label>
                <input
                    placeholder="Apt, Suite, Unit, Building (optional)"
                    name="address2"
                    autoComplete="address-line2"
                    value={address2}
                    onChange={(e) => setAddress2(e.target.value)}
                ></input>
                <div className="city-state-holder flex-row">
                    <div className="input-container">
                        <label htmlFor="city">City:</label>
                        <input
                            required
                            value={city}
                            name="city"
                            autoComplete="address-level2"
                            onChange={(e) => setCity(e.target.value)}
                        ></input>
                    </div>
                    <div className="input-container">
                        <label htmlFor="state">State:</label>
                        <State value={state} onChange={(v) => setState(v)} />
                    </div>
                </div>
                <ContinueButton
                    type="submit"
                    text={userState.step.buttonText}
                    onClick={() => {}}
                />
            </div>
            <OffsetBackground topSpacing="4rem" />
        </form>
    );
};
