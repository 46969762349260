import { EventType } from "@pushnami/models-pivot-tracking/dist/models";
import { waitForSessionToken } from "./helper";
import { StepConfig } from "../models/step_config";

export enum Stage {
    CoReg = "Stage 1",
    OfferSelection = "Stage 2",
    OfferWall = "Stage 2.1",
    RewardGame = "Stage 3",
    EndOfPathRedirect = "Stage 4",
}

export const trackUserProgress = async (stage: Stage) => {
    await waitForSessionToken();
    await window.track(EventType.UserProgress, { stage });
};

export const trackUserStep = async (step: StepConfig) => {
    await waitForSessionToken();
    await window.track(EventType.UserProgress, { stage: step.step });
}

export const trackTcpaUserData = async (jornaya: string) => {
    await waitForSessionToken();
    await window.track(EventType.TCPAUserData, { jornaya });
};

export const trackUserBlocks = async (reason: string) => {
    await waitForSessionToken();
    await window.track(EventType.Blocks, { reason });
};

export const pauseTracking = () => {
    window.pauseTracking();
}
