import { useState, FormEvent } from "react";
import { useUserState } from "../../state/user_state";
import { ContinueButton } from "../continue_button/continue_button";
import { getDaysInMonth, months, years } from "../date_of_birth";
import styles from "./theme.module.css";
import { getThemeClass } from "../../helpers/theme_helper";
import { FaMale, FaFemale } from "react-icons/fa";
import { SubHeaderTemplated } from "../sub_header/sub_header_templated";
import { PhoneTcpa } from "../tcpa/phone_tcpa";
import { GenderType } from "../../models/gender";
import { GenderIconButtons } from "../gender/gender_icon_buttons";
import { DobForm } from "../dob/dob_form";
import { AddressForm } from "../address/address_form";
import { OffsetBackground } from "../offset_background";
import { validatePhone } from "../../helpers/phone_validation";

export const FirstLastPhoneAddressDobGender = () => {
    const userState = useUserState();
    const [firstName, setFirstName] = useState(userState.firstName || "");
    const [lastName, setLastName] = useState(userState.lastName || "");
    const [address1, setAddress1] = useState(userState.address1 || "");
    const [city, setCity] = useState(userState.city || "");
    const [state, setState] = useState(userState.state || "");
    const [phone, setPhone] = useState(userState.phone || "");
    const [zipCode, setZipCode] = useState(userState.zipCode || "");
    const [phoneAllowed, setPhoneAllowed] = useState(
        userState.phoneAllowed || false
    );
    const [phoneError, setPhoneError] = useState("");
    const [month, setMonth] = useState(userState.month || "");
    const [year, setYear] = useState(userState.year || "");
    const [day, setDay] = useState(userState.day || "");
    const [gender, setGender] = useState<GenderType | null>(userState.gender);
    const submit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const validPhone = validatePhone(phone, setPhoneError);
        if (!validPhone) return;

        setPhoneError("");

        userState.setGender(gender);
        userState.setLastName(lastName);
        userState.setFirstName(firstName);
        userState.setMonth(month);
        userState.setYear(year);
        userState.setDay(day);
        userState.setPhone(phone);
        userState.setPhoneAllowed(phoneAllowed);
        userState.setAddress1(address1);
        userState.setZipCode(zipCode);
        userState.setState(state);
        userState.setCity(city);

        userState.nextStep();
    };
    const daysPerMonth = getDaysInMonth(year ? parseInt(year) : years[0]);
    const days =
        months.indexOf(month) != -1 ? daysPerMonth[months.indexOf(month)] : 31;
    return (
        <div className="relative pb-4">
            <form onSubmit={submit} className={getThemeClass("form", styles)}>
                <div
                    className={`
                        input-container 
                        ${getThemeClass("input-container", styles)}`}
                >
                    <SubHeaderTemplated baseText="Almost Done! Who are we sending your free samples to?" />
                    <div className="flex sm:gap-4 gap-2">
                        <input
                            autoFocus
                            required
                            className={`${getThemeClass(
                                "input",
                                styles
                            )} w-1/2`}
                            value={firstName}
                            name="firstName"
                            placeholder="First Name"
                            autoComplete="given-name"
                            onChange={(e) => setFirstName(e.target.value)}
                        ></input>
                        <input
                            required
                            value={lastName}
                            name="lastName"
                            className={`${getThemeClass(
                                "input",
                                styles
                            )} w-1/2`}
                            placeholder="Last Name"
                            autoComplete="family-name"
                            onChange={(e) => setLastName(e.target.value)}
                        ></input>
                    </div>
                    <input
                        required
                        className={getThemeClass("input", styles)}
                        maxLength={11}
                        name="phone"
                        placeholder="Enter Phone Number"
                        value={phone}
                        type="tel"
                        onChange={(e) => setPhone(e.target.value)}
                    ></input>
                    {phoneError && <p style={{ color: "red" }}>{phoneError}</p>}
                    <AddressForm
                        autoFocus={false}
                        address1={address1}
                        city={city}
                        state={state}
                        zipCode={zipCode}
                        setAddress1={setAddress1}
                        setCity={setCity}
                        setState={setState}
                        setZipCode={setZipCode}
                    />
                    <DobForm
                        day={day}
                        month={month}
                        year={year}
                        setDay={setDay}
                        setMonth={setMonth}
                        setYear={setYear}
                    />
                    <GenderIconButtons gender={gender} setGender={setGender} />
                    <PhoneTcpa
                        setPhoneAllowed={setPhoneAllowed}
                        phoneAllowed={phoneAllowed}
                        submitText={userState.step.buttonText}
                    />
                    <ContinueButton
                        type="submit"
                        text={userState.step.buttonText}
                        onClick={() => {
                            userState.setLastName(lastName);
                            userState.setFirstName(firstName);
                        }}
                    />
                </div>
            </form>
        </div>
    );
};
