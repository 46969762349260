import { useUserState } from "../../state/user_state";
import { waitForSessionToken } from "../../tracking/helper";
import { getTrackingSession } from "../../tracking";
import { useEffect, useState } from "react";
import { PivotApi } from "../../api/pivot";
import { handleBlockRequest } from "./helpers";

export const Ipqs = () => {
    let [latestEmail, setLatestEmail] = useState("");
    const state = useUserState();

    useEffect(() => {
        const trackIfAllDataIsAvailable = async () => {
            const baseUrl = state.websiteConfig?.pivotLinkOutUrl;
            if (latestEmail && baseUrl) {
                const response = await PivotApi.check(baseUrl, latestEmail, getTrackingSession())
                const json = await response.json();
                if (json.success && !json.result) {
                    await handleBlockRequest("ipqs");
                }
            }
        }
        trackIfAllDataIsAvailable().catch(console.error);
    }, [latestEmail]);

    useEffect(() => {
        waitForSessionToken().then(() => {
            const sessionToken = getTrackingSession()
            const baseUrl = state.websiteConfig?.pivotLinkOutUrl;
            const newEmail = state.email;
            if (sessionToken && baseUrl && newEmail && newEmail !== latestEmail) {
                setLatestEmail(newEmail);
            }
        });
    }, [state]);
    return null;
};