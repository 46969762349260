import React, { useEffect } from "react";

import TrackingService from "../tracking";
import { Stage, trackUserProgress } from "../tracking/user";

declare global {
    interface Window {
        track: typeof TrackingService.prototype.trackEvent
        pauseTracking: typeof TrackingService.prototype.pause
    }
}

const Tracking: React.FC = () => {
    useEffect(() => {
        const trackingService = new TrackingService();
        window.track = trackingService.trackEvent.bind(trackingService);
        window.pauseTracking = trackingService.pause.bind(trackingService);
        trackUserProgress(Stage.CoReg);
    }, []);

    return null;
};

export default Tracking;
