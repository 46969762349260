import { useEffect, useRef } from "react";
import { PathConfig } from "../models/config";
import {useUserState} from "./user_state";
import {startupParameters} from "./startup";

export const usePathConfig = (): PathConfig | null => {
    const loading = useRef<boolean>(false);
    const { setPathConfig, pathConfig } = useUserState((s) => {
        return { setPathConfig: s.setPathConfig, pathConfig: s.pathConfig };
    });

    useEffect(() => {
        if (pathConfig || loading.current) return;
        loading.current = true;
        const fetchConfig = async () => {
            try {
                const response = await fetch(
                    `${startupParameters.cdn.manifest}/${startupParameters.path.variant}/manifest.json`
                );
                const data = await response.json();
                setPathConfig(data);
            } catch (error) {
                console.error("Failed to fetch config:", error);
            }
        };

        fetchConfig();
    }, []);

    return pathConfig;
};
