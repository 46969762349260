import { FormEvent, useState } from "react";
import { ContinueButton } from "../continue_button/continue_button";
import { State } from "../state";
import { useUserState } from "../../state/user_state";
import { validatePhone } from "../../helpers/phone_validation";
import styles from "./theme.module.css";
import { getThemeClass } from "../../helpers/theme_helper";
import { PhoneTcpa } from "../tcpa/phone_tcpa";
import { AddressForm } from "../address/address_form";

export const AddressPhoneZipForm = () => {
    const userState = useUserState();
    const [address1, setAddress1] = useState(userState.address1 || "");
    const [phone, setPhone] = useState(userState.phone || "");
    const [zipCode, setZipCode] = useState(userState.zipCode || "");
    const [phoneAllowed, setPhoneAllowed] = useState(
        userState.phoneAllowed || false
    );
    const [phoneError, setPhoneError] = useState("");
    const [city, setCity] = useState(userState.city || "");
    const [state, setState] = useState(userState.state || "");
    const submit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const validPhone = validatePhone(phone, setPhoneError);
        if (!validPhone) return;

        setPhoneError("");
        userState.setPhone(phone);
        userState.setZipCode(zipCode);
        userState.setPhoneAllowed(phoneAllowed);
        userState.setAddress1(address1);
        userState.setCity(city);
        userState.setState(state);
        userState.nextStep();
    };

    return (
        <form onSubmit={submit} className={getThemeClass("form", styles)}>
            <div className="input-container">
                <h2 className="input-statement">
                    {userState.step.subHeader ??
                        "Almost done! Where do we send your free samples?"}
                </h2>
                <AddressForm
                    autoFocus
                    address1={address1}
                    city={city}
                    state={state}
                    zipCode={zipCode}
                    setAddress1={setAddress1}
                    setCity={setCity}
                    setState={setState}
                    setZipCode={setZipCode}
                />

                <input
                    required
                    className={getThemeClass("input", styles)}
                    maxLength={11}
                    name="phone"
                    placeholder="Enter Phone Number"
                    value={phone}
                    type="tel"
                    onChange={(e) => setPhone(e.target.value)}
                ></input>
                {phoneError && <p style={{ color: "red" }}>{phoneError}</p>}
                <PhoneTcpa
                    setPhoneAllowed={setPhoneAllowed}
                    phoneAllowed={phoneAllowed}
                    submitText={userState.step.buttonText}
                />
                <ContinueButton
                    type="submit"
                    text={userState.step.buttonText}
                    onClick={() => {}}
                />
            </div>
        </form>
    );
};
