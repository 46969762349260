import { useEffect, useRef } from "react";
import { useUserState } from "../state/user_state";
import { trackTcpaUserData } from "../tracking/user";

interface Props {
    setLeadId?: (leadId: string) => void;
    name?: string;
}

export const LeadId = ({ setLeadId, name }: Props) => {
    const userState = useUserState();
    const leadIdRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const timer = setInterval(() => {
            if (leadIdRef.current && leadIdRef.current.value) {
                console.log(leadIdRef.current.value);
                if (setLeadId && leadIdRef.current.value !== userState.leadId) {
                    setLeadId(leadIdRef.current.value);
                } else if (
                    setLeadId &&
                    leadIdRef.current.value == userState.leadId
                ) {
                    // Generate a new token to cause this component to refresh
                    (window as any).LeadiD.reInit();
                    return;
                } else if (!userState.leadId) {
                    userState.setLeadId(leadIdRef.current.value);
                    trackTcpaUserData(leadIdRef.current.value);
                }

                clearInterval(timer);
            }
        }, 500);
    }, []);
    return (
        <input
            ref={leadIdRef}
            id="leadid_token"
            name={name || "universal_leadid"}
            type="hidden"
            value=""
        />
    );
};
