export const getDomainAndSubdomain = () : { domain: string; subdomain?: string } => {
    const url = new URL(window.location.href);
    const hostnameParts = url.hostname.split('.');

    // Check if there is a subdomain present
    if (hostnameParts.length > 2) {
      const domain = hostnameParts.slice(-2).join('.');
      const subdomain = hostnameParts.slice(0, -2).join('.');
      return { domain, subdomain };
    } else {
      const domain = hostnameParts.join('.');
      return { domain };
    }
  }
  