import { getTrackingSession } from ".";

const hasSessionToken = () => {
    return Boolean(getTrackingSession());
};

export const waitForSessionToken = async () => {
    return new Promise<void>((resolve) => {
        if (hasSessionToken()) {
            resolve();
        } else {
            const timer = setInterval(() => {
                if (hasSessionToken()) {
                    clearInterval(timer);
                    resolve();
                }
            }, 1000);
        }
    });
};

export const sleep = async (time: number) => {
    return new Promise((res) => {
        setTimeout(res, time);
    })
}