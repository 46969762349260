import { getThemeClass } from "../../helpers/theme_helper";
import { State } from "../state";

import styles from "./theme.module.css";

interface Props {
    state: string;
    address1: string | undefined;
    city: string | undefined;
    zipCode: string | undefined;
    autoFocus: boolean;
    setAddress1: (value: string) => void;
    setCity: (value: string) => void;
    setState: (value: string) => void;
    setZipCode: (value: string) => void;
}

export const AddressForm = ({
    address1,
    city,
    state,
    zipCode,
    autoFocus,
    setAddress1,
    setCity,
    setState,
    setZipCode,
}: Props) => {
    return (
        <>
            <input
                autoFocus={autoFocus}
                required
                className={getThemeClass("input", styles)}
                value={address1}
                name="address1"
                placeholder="Street Address"
                autoComplete="address-line1"
                onChange={(e) => setAddress1(e.target.value)}
            ></input>
            <div className="flex flex-row justify-between gap-4">
                <input
                    required
                    value={city}
                    className={`${getThemeClass("input", styles)} w-full`}
                    name="city"
                    placeholder="City"
                    autoComplete="address-level2"
                    onChange={(e) => setCity(e.target.value)}
                ></input>
                <State
                    value={state}
                    onChange={(v) => setState(v)}
                    className={getThemeClass("input", styles)}
                />
            </div>
            <input
                value={zipCode}
                required
                className={getThemeClass("input", styles)}
                minLength={5}
                maxLength={5}
                name="zipCode"
                placeholder="Zip Code"
                autoComplete="postal-code"
                onChange={(e) => setZipCode(e.target.value)}
            ></input>
        </>
    );
};
