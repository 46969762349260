import { useCallback } from "react";
import { getThemeClass } from "../../helpers/theme_helper";
import { Answer, Qualifier } from "../../models/qualifier";
import { useUserState } from "../../state/user_state";
import styles from "./qualifier.module.css";
import { trackQualifierAnswer } from "../../tracking/offers";
interface QualifierProps {
    qualifier: Qualifier;
    offerId: string;
}

export const QualifierCreative = ({ qualifier, offerId }: QualifierProps) => {
    const themeClass = getThemeClass("qualifier-button", styles);
    const state = useUserState();
    const onClick = useCallback(
        (answer: Answer) => {
            Object.keys(answer.Metadata).forEach((k) => {
                const v = answer.Metadata[k];
                state.addMetadataKeys([`${v.Attribute}:${v.Value}`]);
            });
            trackQualifierAnswer(
                offerId,
                qualifier.id,
                answer.Id,
                answer.Text,
                state.psfp || "",
                state.email || "",
                state.phone || ""
            );
        },
        [state]
    );
    return (
        <div className="qualifier-container flex flex-col justify-center items-center bg-white px-12 py-8 rounded-md">
            <div className="qq-header-container mb-8">
                <h2 className="qq-header-text text-2xl font-bold">
                    {qualifier.question}
                </h2>
            </div>
            <div className="qq-answer-container flex flex-col gap-4 w-full">
                {qualifier.answers.map((a) => {
                    return (
                        <button
                            onClick={() => {
                                onClick(a);
                            }}
                            key={a.Id}
                            className={`soi-answer-button px-12 py-3 rounded-2xl text-white font-bold transition hover:scale-110 ${themeClass}`}
                        >
                            {a.Text}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};
