import React, {useEffect} from "react";
import "./roulette.css";
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";

export interface Props {
    cashPrize: string;
    prizePaymentMethod: string;
    officialRulesLink: string;
    borderImage: string;
    spinnerImage: string;
    nextButtonContent?: string;
    onNext: () => unknown;
}

export const Roulette = (props: Props) => {
    const [isSpinning, setIsSpinning] = React.useState(false);
    const [showConfetti, setShowConfetti] = React.useState(false);
    const spinnerRef = React.useRef<HTMLImageElement>(null);

    useEffect(() => {
        if (!spinnerRef.current || !isSpinning || spinnerRef.current.classList.contains('spin')) {
            return;
        }
        spinnerRef.current.classList.add('spin');
        spinnerRef.current.addEventListener('animationend', () => {
            setShowConfetti(true);
            setTimeout(() => props.onNext(), 1250);
        })
    }, [spinnerRef, isSpinning])

    const startSpinning = (e: React.FormEvent) => {
        e.preventDefault();
        setIsSpinning(true);
    };

    return (
        <form className="step landing roulette" onSubmit={startSpinning}>
            <h1>Spin for FREE play <span>{props.cashPrize}</span> <sub>{props.prizePaymentMethod}</sub></h1>
            <div className="roulette-animation" onClick={startSpinning}>
                <img src={props.spinnerImage} alt="Roulette spinner" ref={spinnerRef}/>
                <img src={props.borderImage} alt="Roulette border"/>
            </div>
            <button type="submit">{props.nextButtonContent || 'Unlock my play'}</button>
            {showConfetti && <Fireworks autorun={{speed: 2}}/>}
        </form>
    );
};
