export const PhoneTcpaDefaultContent = (props: { submitText?: string, siteBaseUrl: string }) => <p>
    By selecting this box and clicking "{props.submitText || "Submit"}", I provide my E-SIGN signature and express
    written consent to contact from Prizestash.com, My Benefits Club [MBC], Unified Marketing Partners LLC,
    Freedomlender, Horizon Benefits [HZB], Ad Heavens [ADH], Americas Health, Health Advisors, Jesus Network
    (JSN), Biologistics, or Grantexa at the number I provided above regarding products or services via live,
    automated telephone call, text, or email, even if that number is on any local, state or national "Do Not
    Call" list. I consent to these telephone calls being monitored or recorded. I understand that my telephone
    company may impose charges on me for these contacts, and I am not required to enter into this agreement as a
    condition of any purchase. I can revoke this consent at any time by notifying us or our partners directly
    through any reasonable means. I agree to the <a
    href={`${props.siteBaseUrl}/terms-and-conditions`}
    target="_blank"
    className="hover:underline"
> Terms of Service </a> and <a
    href={`${props.siteBaseUrl}/privacy-policy`}
    target="_blank"
    className="hover:underline"
> Privacy Policy </a>. Reply HELP for help, or STOP to opt out. Number of messages may vary. Message and
    data rates may apply.
</p>