interface ZipCodeResponse {
    city: string;
    // Full state
    state: string;
    zip: string;
}

export const lookupZip = async (
    zipCode: string
): Promise<ZipCodeResponse | null> => {
    try {
        const result = await fetch(
            `https://rn4qjf4q37.execute-api.us-east-1.amazonaws.com/pivot-zip-lookup?zipCode=${zipCode}`
        );
        const zipResponse = await result.json();
        return zipResponse;
    } catch (err) {
        return null;
    }
};
