import { useEffect, useState } from "react";
import { Ipqs } from "./ipqs";
import { Anura } from "./anura";
import { useUserState } from "../../state/user_state";
import { trackUserBlocks } from "../../tracking/user";

export const Blocking = () => {
    const [randomValue] = useState(Math.random());
    const websiteConfig = useUserState((s) => s.websiteConfig);

    useEffect(() => {
        if (websiteConfig) {
            if (!websiteConfig.disableAnura) {
                trackUserBlocks(
                    randomValue < 0.5 ? "anura-check" : "ipqs-check"
                );
            } else {
                trackUserBlocks("blocks-disabled");
            }
        }
    }, [randomValue, websiteConfig]);

    if (!websiteConfig || websiteConfig.disableAnura) {
        return null;
    }
    return randomValue < 0.5 ? <Anura /> : <Ipqs />;
};
