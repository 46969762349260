import type { StepConfig } from "../models/step_config";
import type { State } from "../state/user_state";
import { isValidStateKey, Step } from "../state/user_state";

/**
 * Computes all the fields that can be skipped for a given step.
 *
 * @param step The step to check for skippable fields.
 * @returns An array of field names that can be skipped for the given step, or undefined if the step can not be skipped.
 */
const getSkippableFields = (step: Step): string[] | undefined => {
    switch (step) {
        case Step.Address:
            return ["address1", "city", "state"];
        case Step.AddressPhoneZip:
            return ["address1", "city", "state", "zipCode"];
        case Step.AutoRegConfirmation:
            return ["firstName", "lastName", "phone", "email"];
        case Step.DateOfBirth:
            return ["month", "year", "day"];
        case Step.Email:
        case Step.EmailV2:
            return ["email"];
        case Step.EmailPhone:
            return ["email", "phone"];
        case Step.FirstLastDobGender:
            return ["firstName", "lastName", "month", "year", "day", "gender"];
        case Step.FirstLastPhoneAddressDobGender:
            return [
                "firstName",
                "lastName",
                "address1",
                "city",
                "state",
                "month",
                "year",
                "day",
                "gender",
            ];
        case Step.Gender:
            return ["gender"];
        case Step.Name:
            return ["firstName", "lastName"];
        case Step.Phone:
            return ["phone"];
        case Step.ZipCode:
            return ["zipCode"];
        case Step.LinkOut:
        case Step.Loading:
        case Step.LookingForOffersV1:
        case Step.Path:
        case Step.PathSecondary:
        case Step.Quiz:
        case Step.Reward:
        case Step.ThankYou:
        case Step.Unlock:
        case Step.UnlockSamples:
        case Step.OfferWall:
            return undefined;
        default:
            throw new Error(`Unknown step: ${step}`);
    }
};

export const canSkipToConfirmation = (state: State): boolean => {
    const onFirstStep = state.path.indexOf(state.step) === 0;
    const firstOfferIndex = state.path.findIndex((step) => isOfferStep(step.step));

    return onFirstStep &&
        firstOfferIndex >= 1 &&
        state.path
            .slice(1, firstOfferIndex)
            .every((step) => stepCanBeSkipped(state, step.step));
}

const stepCanBeSkipped = (state: State, step: Step): boolean => {
    const skippableFields = getSkippableFields(step);
    return skippableFields !== undefined && skippableFields.every(
        (field) => isValidStateKey(field, state) && !!state[field],
    );
}

export const replaceNextSkippableStepsWithSingleConfirmationStep = (state: State, set: (update: Partial<State>) => void): StepConfig => {
    let firstOfferIndex = state.path.findIndex((step) => isOfferStep(step.step));
    if (firstOfferIndex === -1) {
        firstOfferIndex = state.path.length;
    }
    let currentStepIndex = state.path.indexOf(state.step);
    if (currentStepIndex < 0) {
        currentStepIndex = 0;
    }
    const autoRegConfirmation: StepConfig = { step: Step.AutoRegConfirmation };
    set({
        path: state.path
            .slice(0, currentStepIndex + 1)
            .concat(autoRegConfirmation, ...state.path.slice(firstOfferIndex)),
    })
    return autoRegConfirmation
};

const isOfferStep = (step: Step): boolean => {
    return step === Step.Path || step === Step.LinkOut;
}
