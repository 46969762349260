import { ContinueButton } from "../../components/continue_button/continue_button";
import "../../components/input.css";
import { useUserState } from "../../state/user_state";

export const Info = () => {
    const nextStep = useUserState((s) => s.nextStep);
    const step = useUserState((s) => s.step);
    return (
        <div>
            <div className="input-container ss">
                <ContinueButton
                    onClick={() => {
                        nextStep();
                    }}
                    type="button"
                    text={step.buttonText ?? "Submit"}
                />
            </div>
        </div>
    );
};
