import { EventType } from "@pushnami/models-pivot-tracking/dist/models";
import { getDomainAndSubdomain } from "../helpers/url_helper";
import { v4 as uuidv4 } from "uuid";
import {startupParameters} from "../state/startup";

const TRACKING_API = process.env.TRACKING_API;

export const getTrackingSession = () => session;

let session = "";
let psfpIsTracked = false;
let psidIsTracked = false;

export default class TrackingService {
    private sessionToken: string | null;    // Unique ID for each page load (visit ID)
    private sessionKey: string;        // Persistent identifier for the entire session
    private enabled: boolean;

    constructor() {
        this.enabled = true;
        this.sessionToken = getTrackingSession();
        if (!this.sessionToken) {
            this.initializeSession();
        }

        this.sessionKey = this.getOrCreateSessionKey()
    }


    // Initializes the session by creating a new session token and tracking the session event
    private async initializeSession(): Promise<void> {
        try {
            const token = await this.createSessionToken();
            if (token) {
                this.sessionToken = token;
                session = token;
                await this.trackSessionEvent();
            }
        } catch (error) {
            console.error("Failed to initialize session:", error);
        }
    }

    // Tracks an event by sending the event data to the server
    public async trackEvent(
        eventType: EventType,
        payload: Record<string, unknown>
    ): Promise<void> {
        if (!this.enabled && eventType != EventType.Blocks) {
            return;
        }

        if (!this.sessionToken) {
            console.error("No session token available. Unable to track event.");
            return;
        }

        // Track PSFP/PSID if we haven't track them yet
        if (eventType !== EventType.Session && this.shouldTrackPsfpAndPsid()) {
            this.trackSessionEvent();
        }

        try {
            const response = await fetch(
                `${TRACKING_API}/track/${this.sessionToken}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ event: eventType, data: payload }),
                }
            );

            if (!response.ok) {
                throw new Error(
                    `Error tracking event: ${response.status} ${response.statusText}`
                );
            }
        } catch (error) {
            console.error("Error tracking event:", error);
        }
    }

    // Fetches a new session token from the TRACKING_API
    private async createSessionToken(): Promise<string> {
        try {
            const response = await fetch(`${TRACKING_API}/session`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
            });

            if (!response.ok) {
                throw new Error(
                    `Error: ${response.status} ${response.statusText}`
                );
            }

            const data = await response.json();
            return data.token || "";
        } catch (error) {
            console.error("Error creating session:", error);
            return "";
        }
    }

    private getOrCreateSessionKey(): string {
        let sessionKey = sessionStorage.getItem("sessionKey");
        if (!sessionKey) {
            sessionKey = uuidv4();
            sessionStorage.setItem("sessionKey", sessionKey);
        }
        return sessionKey;
    }

    // Tracks the initial session data including domain, psfp, and psid
    private async trackSessionEvent(): Promise<void> {
        const { domain, subdomain } = getDomainAndSubdomain();
        const theme = startupParameters.ux.theme;
        const variant = startupParameters.path.variant;
        const source = startupParameters.publisher.utm_source;
        const utm_medium = startupParameters.publisher.utm_medium;
        const utm_content = startupParameters.publisher.utm_content;
        const utm_term = startupParameters.publisher.utm_term;
        const click_id = startupParameters.publisher.click_id;
        let psfp = (window as any).Pushnami?.psfp?.data;
        let psid = (window as any).Pushnami?.getPSID();
        psfp = typeof psfp === "string" ? psfp : "";
        psid = typeof psid === "string" ? psid : "";

        const payload = {
            domain: subdomain ? `${subdomain}.${domain}` : domain,
            psfp,
            psid,
            theme,
            variant,
            source: `${source || ""}`,
            utm_medium: `${utm_medium || ""}`,
            utm_content: `${utm_content || ""}`,
            utm_term: `${utm_term || ""}`,
            click_id: `${click_id || ""}`,
            session_key: this.sessionKey
        };

        await this.trackEvent(EventType.Session, payload);

        if (psfp && psfp != "") psfpIsTracked = true;
        if (psid && psid != "") psidIsTracked = true;
    }

    private shouldTrackPsfpAndPsid = (): boolean => {
        const psfp = (window as any).Pushnami?.psfp?.data;
        const psid = (window as any).Pushnami?.getPSID();

        return (psfp && !psfpIsTracked) || (psid && !psidIsTracked);
    };

    public pause(): void {
        this.enabled = false;
    }
}
