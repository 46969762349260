import { Theme, useTheme } from "../state/theme";
import { Step, useUserState } from "../state/user_state";
import "./offset_background.css";

export const OffsetBackground = ({ topSpacing }: { topSpacing: string }) => {
    const step = useUserState((s) => s.step);

    const theme = useTheme();

    if (theme === Theme.SAMPLES || theme === Theme.AIO) {
        return null;
    }

    const extraClass =
        step.step === Step.Unlock ? "unlock-background" : "offset-background";

    const topCss =
        step.step === Step.Path ||
        step.step === Step.PathSecondary ||
        step.step === Step.Reward
            ? "top-0"
            : "";
    let style = topCss === "" ? { top: `${topSpacing}` } : {};
    return (
        <div
            style={style}
            className={`fill ${topCss} -z-10 left-0 right-0 bottom-0 absolute rounded-2xl ${extraClass}`}
        ></div>
    );
};
