export const convertKeysToAttributes = (keys: string[]) => {
    const validKeys = keys.filter((k) => k.indexOf(":") != -1);

    const map: Record<string, string | number | boolean> = {};

    validKeys.forEach((k) => {
        const split = k.split(":");
        map[split[0]] = convertStringToValue(split[1]);
    });

    return map;
};

export const convertStringToValue = (
    str: string
): string | boolean | number => {
    // Try to convert to boolean
    if (str.toLowerCase() === "true") return true;
    if (str.toLowerCase() === "false") return false;

    // Try to convert to float
    const floatVal = parseFloat(str);
    if (!isNaN(floatVal)) return floatVal;

    // Default to returning the original string
    return str;
};
