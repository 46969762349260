import { EventType } from "@pushnami/models-pivot-tracking/dist/models";
import { waitForSessionToken } from "./helper";
import { Stage } from "./user";

interface OfferWallTrackMeta extends Record<string,unknown> {
    utmSource?: string,
    utmContent?: string,
    utmTerm?: string,
    email?: string,
    zipCode?: string
}
type OfferWallTrackMetaNullValues = Record<keyof OfferWallTrackMeta, any>

export const trackOfferWall = async (event: EventType, meta: OfferWallTrackMetaNullValues) => {
    await waitForSessionToken();
    const cleanMeta = createMeta(meta)
    window.track(event, {
        stage: Stage.OfferWall,
        ...cleanMeta
    })
}


export const createMeta = (metaNulls: OfferWallTrackMetaNullValues) => {
const meta: OfferWallTrackMeta = {}
return Object.entries(metaNulls).reduce((acc: OfferWallTrackMeta, [key, val]) => {
    if(typeof val === 'string' && val.length > 0) {
        acc[(key as keyof OfferWallTrackMeta)] = val 
    }

    return acc
}, meta)
}
