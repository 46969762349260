import React, {useEffect} from "react";
import {ZipCode} from "./components/zip_code";
import {Step, useUserState} from "./state/user_state";
import {Name} from "./components/name";
import {Email} from "./components/email";
import {Phone} from "./components/phone";
import {Address} from "./components/address";
import {ZeetoPlacement} from "./components/zeeto/zeeto_placement";
import {DateOfBirth} from "./components/date_of_birth";
import {Gender} from "./components/gender";
import {Unlock} from "./steps/landing/unlock";
import {Reward} from "./steps/reward_step";
import {Loading} from "./steps/loading";
import {LookingForOffersV1} from "./components/path_incentives/looking_for_offers_v1";
import {UnlockSamples} from "./components/unlock_samples/unlock_samples";
import {AddressPhoneZipForm} from "./components/combo_forms/address_phone_zip";
import {FirstLastDobGenderForm} from "./components/combo_forms/first_last_dob_gender";
import {EmailV2} from "./components/email_v2/email_v2";
import {FirstLastPhoneAddressDobGender} from "./components/combo_forms/first_last_phone_address_dob_gender";
import {EmailPhoneForm} from "./components/combo_forms/email_phone";
import {QuizForm} from "./components/quiz/quiz";
import {LinkOutView} from "./components/linkout/linkout";
import {ThankYou} from "./components/thank_you";
import {AutoRegConfirmation} from "./components/combo_forms/auto_reg_confirmation";
import {OfferWallPlacement} from "./components/offer_wall/offer_wall";
import {startupParameters} from "./state/startup";

export const Path = (config: {
    onComplete: () => unknown;
}) => {
    const state = useUserState();

    useEffect(() => {
        if (window && window.scrollTo) window.scrollTo(0, 0);
    }, [state.step.step]);

    switch (state.step.step) {
        case Step.Unlock:
            return <Unlock/>;
        case Step.UnlockSamples:
            return <UnlockSamples/>;
        case Step.ZipCode:
            return <ZipCode/>;
        case Step.Name:
            return <Name/>;
        case Step.Email:
            return <Email/>;
        case Step.Phone:
            return <Phone/>;
        case Step.Address:
            return <Address/>;
        case Step.DateOfBirth:
            return <DateOfBirth/>;
        case Step.Gender:
            return <Gender/>;
        case Step.Path:
        case Step.PathSecondary:
            return <ZeetoPlacement/>;
        case Step.Reward:
            if (!startupParameters.ux.showReward) {
                config.onComplete();
                return null;
            }
            return <Reward onComplete={config.onComplete}/>;
        case Step.Loading:
            return <Loading/>;
        case Step.LookingForOffersV1:
            return <LookingForOffersV1/>;
        case Step.AddressPhoneZip:
            return <AddressPhoneZipForm/>;
        case Step.FirstLastDobGender:
            return <FirstLastDobGenderForm/>;
        case Step.EmailV2:
            return <EmailV2/>;
        case Step.FirstLastPhoneAddressDobGender:
            return <FirstLastPhoneAddressDobGender/>;
        case Step.EmailPhone:
            return <EmailPhoneForm/>;
        case Step.Quiz:
            return <QuizForm />;
        case Step.OfferWall:
            return <OfferWallPlacement />
        case Step.LinkOut:
            return <LinkOutView/>;
        case Step.ThankYou:
            return <ThankYou onComplete={config.onComplete}/>;
        case Step.AutoRegConfirmation:
            return <AutoRegConfirmation />;
    }
    return null;
};
