import { useOfferConfig } from "../../state/offer_config";
import {Step, useUserState} from "../../state/user_state";
import {startupParameters} from "../../state/startup";

export const OrderProgress = () => {
    const step = useUserState((s) => s.step);
    const offerConfig = useOfferConfig();
    switch (step.step) {
        case Step.Unlock:
        case Step.UnlockSamples:
        case Step.Path:
        case Step.PathSecondary:
        case Step.Reward:
        case Step.Loading:
            return null;
    }

    return (
        <div className="flex flex-row bg-white rounded-md shadow-lg p-4 border-gray-50 mt-4 sm:w-3/5 w-[90%]">
            <div className="sm:w-1/5 w-1/3 my-auto">
                <img
                    src={`${startupParameters.cdn.origin}${offerConfig?.rewardImageUri || ''}`}
                    alt=""
                    loading="lazy"
                    className="pr-2"
                ></img>
            </div>
            <div className="flex-1">
                <div className="w-full text-purple-600 font-bold">
                    Order In Process
                </div>
                <div className="border-b-purple-600 w-full border-b-2"></div>
                <div className="flex flex-row justify-between">
                    <div className="font-thin text-sm">Cost</div>
                    <div className="font-thin text-sm">$0.00</div>
                </div>
                <div className="flex flex-row justify-between">
                    <div className="font-thin text-sm">Shipping</div>
                    <div className="font-thin text-sm">$0.00</div>
                </div>
                <div className="flex flex-row justify-between">
                    <div className="text-purple-600 text-sm font-bold">
                        Total
                    </div>
                    <div className="text-purple-600 text-sm font-bold">
                        $0.00
                    </div>
                </div>
            </div>
        </div>
    );
};

// Page 4 == path secondary

// Last Step Joey!

// Redeem below, then continue to claim your free offers at Scored It!
