export const validatePhone = (
    phone: string,
    setError: (err: string) => void
): boolean => {
    if (
        (phone.length > 0 && phone[0] === "0") ||
        (phone.length !== 11 && phone[0] === "1")
    ) {
        setError("Phone number must not start with 0 or 1");
        return false;
    }
    if (phone.length < 10) {
        setError("Phone number must be at least 10 digits");
        return false;
    }

    if (phone.length === 11 && phone[0] !== "1") {
        setError("Invalid phone number");
        return false;
    }

    if (phone.length > 11) {
        setError("Invalid phone number");
        return false;
    }

    return true;
};
