import { getThemeClass } from "../../helpers/theme_helper";
import { useUserState } from "../../state/user_state";
import styles from "./theme.module.css";

export const AlmostThere = () => {
    const state = useUserState();

    if (
        !state.firstName ||
        state.placementId !== state.pathConfig?.primaryPlacement
    ) {
        return null;
    }

    return (
        <div>
            <p
                className={`reward-message-heading ${getThemeClass(
                    "reward-heading",
                    styles
                )}`}
            >
                2 more steps {state.firstName}!
            </p>
            <p
                className={`reward-message-sub-heading ${getThemeClass(
                    "reward-subheading",
                    styles
                )}`}
            >
                {state.offerConfig?.primaryPlacementText ||
                    "Complete our short survey to qualify and get matched to more offers!"}
            </p>
        </div>
    );
};
