import { useEffect, useRef } from "react";
import { useUserState } from "../../state/user_state";
import { trackUserBlocks } from "../../tracking/user";
import { handleBlockRequest } from "./helpers";

const instance = "3688597576";

const random = Math.floor(Math.random() * 10000000);

window.anura_callback = (response: any) => {
    // if the response ID or EXID is available
    if (response.getId() || response.getExId()) {
        // get the result from Anura servers...
        getResult(response);
    }
};

// get the result from Anura servers using the response object
function getResult(response: any) {
    var method = "POST";
    var params = [`instance=${instance}`];
    if (response.getId())
        params.push("id=" + encodeURIComponent(response.getId()));
    if (response.getExId())
        params.push("exid=" + encodeURIComponent(response.getExId()));
    var url =
        "https://script.anura.io/result.json" +
        ("GET" === method ? "?" + params.join("&") : "");
    // internet explorer 8-9
    if (window.XDomainRequest) {
        let http = new window.XDomainRequest();
        if (http) {
            http.open(
                method,
                document.location.protocol === "https:"
                    ? url
                    : url.replace("https:", "http:")
            );
            http.onprogress = function () {};
            http.ontimeout = function () {};
            http.onerror = function () {};
            http.onload = function () {
                anuraResultHandler(this);
            };
            setTimeout(function () {
                http.send("POST" === method ? params.join("&") : "");
            }, 0);
        }
        // other browsers
    } else if (window.XMLHttpRequest) {
        let http = new XMLHttpRequest();
        if (http && "withCredentials" in http) {
            http.open(method, url, true);
            if ("POST" === method)
                http.setRequestHeader(
                    "Content-type",
                    "application/x-www-form-urlencoded"
                );
            http.onload = function () {
                anuraResultHandler(this);
            };
            http.send("POST" === method ? params.join("&") : "");
        }
    }
}

async function anuraResultHandler(http: any) {
    // Anura responses: https://docs.anura.io/integration/script/response
    const json = JSON.parse(http.response);
    if (json.result && json.result == "bad") {
        await handleBlockRequest("anura");
    } else if (json.result && json.result == "warn") {
        await trackUserBlocks("anura-warn");
    }
}

let script: HTMLScriptElement;

export const Anura = () => {
    const loaded = useRef<boolean>(false);
    const websiteConfig = useUserState((s) => s.websiteConfig);
    useEffect(() => {
        if (loaded.current || !websiteConfig || websiteConfig.disableAnura) {
            return;
        }

        if (document.getElementById("anura-code")) {
            return;
        }

        loaded.current = true;
        script = document.createElement("script");
        script.id = "anura-code";
        script.src = `https://script.anura.io/request.js?instance=${instance}&callback=window.anura_callback&${random}`;
        script.async = true;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, [websiteConfig]);
    return null;
};
