import {useEffect, useRef, useState} from "react";
import { useUserState } from "../../state/user_state";
import { getTrackingSession } from "../../tracking";
import { useSessionKey } from "./useSessionKey";
import {startupParameters} from "../../state/startup";

const fluentOfferwallId = "a48879fa-6ad5-4ead-80c7-e02289260ea8";

export const offerWallDivId = "af-placement";
declare global {
    interface Window {
        FlowContainer: any;
    }
}

/**
 * This hook inserts the script for offerwall into the head of the document
 * and then waits for the required information to start the FlowContainer from Adlogik
 * into a placeholder div so that it is already rendered.
 */
export const OfferWallScript = () => {
    const { utm_source, utm_content, utm_term, click_id } = startupParameters.publisher;
    const { email, firstName, zipCode, gender } = useUserState();
    const [scriptAppended, setScriptAppended] = useState(false);
    const [offerwallLoaded, setOfferWallLoaded] = useState(false);
    const { sessionKey } = useSessionKey();
    const storageSessionKey = getTrackingSession();
    const head = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (!head.current) {
            return;
        }
        const link = window.document.createElement("link");
        const script = document.createElement("script");
        if (!scriptAppended) {
            link.rel = "stylesheet";
            link.href = `https://api.tnapplications.com/assets/${fluentOfferwallId}/flow-container.css`;

            script.referrerPolicy = "no-referrer-when-downgrade";
            script.onload = (_event) => {
                setScriptAppended(true);
                return undefined;
            };
            script.async = true;
            script.src = `https://api.tnapplications.com/assets/${fluentOfferwallId}/flow-container.js`;

            head.current.appendChild(link);
            head.current.appendChild(script);
        }
    }, [scriptAppended, head]);

    useEffect(() => {
        if (
            !offerwallLoaded &&
            scriptAppended &&
            storageSessionKey &&
            gender?.length &&
            zipCode?.length &&
            firstName?.length &&
            email?.length &&
            sessionKey
        ) {
            const offerWallElement = document.createElement("div");
            offerWallElement.id = "af-placement";
            offerWallElement.style.display = "none";
            document.body.appendChild(offerWallElement);
            // @ts-ignore - Flow container is added in the index html document public/index.tmp.html
            const fc = new FlowContainer({
                params: {
                    email,
                    gender,
                    firstname: firstName,
                    zip: zipCode,
                    subaff1: utm_source, // utm_source
                    subaff3: utm_content, // utm_content
                    subaff4: utm_term, // utm_term
                    transactionId: `${sessionKey}::${click_id}`, // https://modernbusinesssolutions.atlassian.net/wiki/spaces/Engineering/pages/526286857/PIVOT+Stage+2.1+-+Offer+Wall#Requirements-for-Integration
                },
            });
            fc.show();
            setOfferWallLoaded(true);
        }
    }, [
        gender,
        zipCode,
        firstName,
        email,
        click_id,
        sessionKey,
        scriptAppended,
        offerwallLoaded,
        storageSessionKey,
    ]);
    return <div ref={head}></div>;
};
