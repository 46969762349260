import {startupParameters} from "./startup";

export enum Theme {
    SWEEPS,
    SAMPLES,
    AIO,
    LARGE_BUTTON,
    RED_BUTTONS,
    LOTTERY,
    INFO
}

export const useTheme = () => {
    const themeParam = startupParameters.ux.theme;
    switch (themeParam) {
        case "samples":
            return Theme.SAMPLES;
        case "aio":
            return Theme.AIO;
        case "lgbtn":
            return Theme.LARGE_BUTTON;
        case "redbtn":
            return Theme.RED_BUTTONS;
        case "lty":
            return Theme.LOTTERY;
        case "inf":
            return Theme.INFO;
        case "sweeps":
        default:
            return Theme.SWEEPS;
    }
};
