import { StepConfig } from "../models/step_config";
import { Step } from "../state/user_state";

export const isPathStep = (step: StepConfig): boolean => {
    switch (step.step) {
        case Step.Path:
        case Step.PathSecondary:
        case Step.LinkOut:
            return true;
        default:
            return false;
    }
};

export const isRewardStep = (step: StepConfig): boolean => {
    return step.step === Step.Reward;
};
