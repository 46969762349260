import { useState, useEffect, useRef } from "react";
import { Step, useUserState } from "../../state/user_state";

let loadedScript = false;

export const ZeetoJS = () => {
    const loaded = useRef<boolean>(false);
    const { step, pathConfig, setZeetoLoaded } = useUserState();

    useEffect(() => {
        const listenerFunc = (event: MouseEvent) => {
            if (event && event.target) {
                if (
                    "className" in event.target &&
                    typeof event.target.className === "string" &&
                    (event.target.className.indexOf("zan-answer-label") !==
                        -1 ||
                        event.target.className.indexOf("zan-label-no") !== -1 ||
                        event.target.className.indexOf("zan-label-yes") !==
                            -1 ||
                        event.target.className.indexOf("zan-label-skip") !== -1)
                ) {
                    if (window && window.scrollTo) window.scrollTo(0, 0);
                }
            }
        };
        document.addEventListener("click", listenerFunc);

        return () => {
            document.removeEventListener("click", listenerFunc);
        };
    }, []);

    useEffect(() => {
        if (loaded.current || !pathConfig || loadedScript) return;
        loaded.current = true;
        setTimeout(() => {
            (function (w: any, d, s, p) {
                var z = (w.zan = w.zan || []),
                    f = d.getElementsByTagName(s)[0] as any,
                    j = d.createElement(s) as any;
                z.b = [];
                z.p = p;
                z.e = 1;
                z.on = function (e: any, cb: any) {
                    z.b.push({ e: e, cb: cb });
                };
                j.async = true;
                j.src = "https://d1hdwri3zez2y9.cloudfront.net/zeeto.js";
                f.parentNode.insertBefore(j, f);
                setZeetoLoaded(true);
                loadedScript = true;
            })(window, document, "script", true);
        }, 500);
    }, [pathConfig]);
    let stepName = step.step;
    const hiddenClass =
        stepName === Step.Path || stepName === Step.PathSecondary
            ? ""
            : "hidden";
    return (
        <div
            id="zeeto"
            className={`px-4 py-4 ${hiddenClass} bg-white rounded-sm`}
        ></div>
    );
};
