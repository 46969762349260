import { useEffect, useMemo, useRef, useState } from "react";
import { useUserState } from "./user_state";

export const useDomainConfig = (domainId: string) => {
    const apiUrl = useUserState((s) => s.websiteConfig?.pivotLinkOutUrl);
    const { setDomainConfig } = useUserState((s) => ({
        setDomainConfig: s.setDomainConfig,
    }));
    useEffect(() => {
        if (!domainId || !apiUrl) {
            return;
        }
        const fetchConfig = async () => {
            const response = await fetch(`${apiUrl}/v1/domains/${domainId}`);
            const data = await response.json();
            setDomainConfig(data);
        };

        fetchConfig().catch((error => console.error("Failed to fetch domain config:", error)));
    }, [apiUrl, domainId]);
};
