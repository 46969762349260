import { FormEvent, useState } from "react";
import { useUserState } from "../state/user_state";

import { ContinueButton } from "./continue_button/continue_button";
import "./input.css";
import { OffsetBackground } from "./offset_background";

export const Name = () => {
    const userState = useUserState();
    const [firstName, setFirstName] = useState(userState.firstName || "");
    const [lastName, setLastName] = useState(userState.lastName || "");

    const submit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        userState.nextStep();
    };

    return (
        <form onSubmit={submit}>
            <div className="input-container">
                <h2 className="input-statement">
                    {userState.step?.subHeader ?? "Thanks! What's your name?"}
                </h2>

                <label htmlFor="firstName">First Name:</label>
                <input
                    autoFocus
                    required
                    value={firstName}
                    name="firstName"
                    autoComplete="given-name"
                    onChange={(e) => setFirstName(e.target.value)}
                ></input>
                <label htmlFor="lastName">Last Name:</label>
                <input
                    required
                    value={lastName}
                    name="lastName"
                    autoComplete="family-name"
                    onChange={(e) => setLastName(e.target.value)}
                ></input>
                <ContinueButton
                    type="submit"
                    text={userState.step.buttonText}
                    onClick={() => {
                        userState.setLastName(lastName);
                        userState.setFirstName(firstName);
                    }}
                />
            </div>
            <OffsetBackground topSpacing="4rem" />
        </form>
    );
};
