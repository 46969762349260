import { useState } from "react";
import "./flip_card.css";
import "../reward_image/image.css";
import {useUserState} from "../../state/user_state";

export const FlipCard = ({
    onFlip,
    backImage,
}: {
    onFlip: () => void;
    backImage?: string;
}) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const websiteConfig = useUserState((s) => s.websiteConfig);

    const handleClick = () => {
        if (!isFlipped) {
            setIsFlipped(!isFlipped);
            onFlip();
        }
    };
    return (
        <div
            className={`flip-card ${
                isFlipped ? "flipped" : ""
            } sm:h-[200px] h-[140px] mt-4`}
            onClick={handleClick}
        >
            <div className="flip-card-inner">
                <div className="flip-card-front">
                    <img
                        src={`${websiteConfig?.logoImageUri}`}
                        className="img-responsive w-[75%]"
                    />
                </div>
                <div className="flip-card-back">
                    <img src={backImage} className="img-responsive" />
                </div>
            </div>
        </div>
    );
};
