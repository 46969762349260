import { AlmostThere } from "../../components/path_incentives/almost_there";
import { CongratsMessage } from "../../components/path_incentives/congrats_message";
import { TopMessage } from "../../components/path_incentives/top_message";
import { ZeetoJS } from "../../components/zeeto/zeeto_js";
import { Path } from "../../path";
import { startupParameters } from "../../state/startup";
import { useUserState } from "../../state/user_state";

export const InfoLayout = (config: { onComplete: () => unknown }) => {
    const { step } = useUserState();
    return (
        <>
            <div className="info-layout-header">{step.header}</div>
            <div className="info-logo-container">
                <img src={startupParameters.ux.logo} />
            </div>
            <div className="inner-container relative z-10">
                <div className="z-10">
                    <Path onComplete={config.onComplete} />
                    <ZeetoJS />
                </div>
            </div>
        </>
    );
};
