import { useState, FormEvent } from "react";
import { useUserState } from "../../state/user_state";
import { ContinueButton } from "../continue_button/continue_button";
import { getDaysInMonth, months, years } from "../date_of_birth";
import styles from "./theme.module.css";
import { getThemeClass } from "../../helpers/theme_helper";
import { GenderType } from "../../models/gender";
import { GenderIconButtons } from "../gender/gender_icon_buttons";

export const FirstLastDobGenderForm = () => {
    const userState = useUserState();
    const [firstName, setFirstName] = useState(userState.firstName || "");
    const [lastName, setLastName] = useState(userState.lastName || "");
    const [month, setMonth] = useState(userState.month || "");
    const [year, setYear] = useState(userState.year || "");
    const [day, setDay] = useState(userState.day || "");
    const [gender, setGender] = useState<GenderType | null>(userState.gender);
    const submit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        userState.setGender(gender);
        userState.setLastName(lastName);
        userState.setFirstName(firstName);
        userState.setMonth(month);
        userState.setYear(year);
        userState.setDay(day);
        userState.nextStep();
    };
    const daysPerMonth = getDaysInMonth(year ? parseInt(year) : years[0]);
    const days =
        months.indexOf(month) != -1 ? daysPerMonth[months.indexOf(month)] : 31;
    return (
        <form onSubmit={submit} className={getThemeClass("form", styles)}>
            <div className="input-container">
                <h2 className="input-statement">
                    {userState.step?.subHeader ??
                        "Almost Done! Who are we sending your free samples to?"}
                </h2>
                <input
                    autoFocus
                    required
                    className={getThemeClass("input", styles)}
                    value={firstName}
                    name="firstName"
                    placeholder="First Name"
                    autoComplete="given-name"
                    onChange={(e) => setFirstName(e.target.value)}
                ></input>
                <input
                    required
                    value={lastName}
                    name="lastName"
                    className={getThemeClass("input", styles)}
                    placeholder="Last Name"
                    autoComplete="family-name"
                    onChange={(e) => setLastName(e.target.value)}
                ></input>
                <div className="date-of-birth-container">
                    <select
                        required
                        className={getThemeClass("input", styles)}
                        onChange={(e) => setMonth(e.currentTarget.value)}
                        value={month ? parseInt(month) : ""}
                        autoComplete="bday-month"
                        name="month"
                    >
                        <option value="" disabled>
                            MM
                        </option>
                        {months.map((m) => {
                            const monthNumber = months.indexOf(m) + 1;
                            return (
                                <option key={m} value={monthNumber}>
                                    {m}
                                </option>
                            );
                        })}
                    </select>
                    <select
                        required
                        className={getThemeClass("input", styles)}
                        autoComplete="bday-day"
                        value={day ? day : ""}
                        onChange={(e) => setDay(e.currentTarget.value)}
                        name="day"
                    >
                        <option value="" disabled>
                            Day
                        </option>
                        {Array.from(
                            { length: days },
                            (_, index) => index + 1
                        ).map((v) => {
                            return (
                                <option key={v} value={`${v}`}>
                                    {v}
                                </option>
                            );
                        })}
                    </select>
                    <select
                        required
                        autoComplete="bday-year"
                        className={getThemeClass("input", styles)}
                        value={year}
                        onChange={(e) => setYear(e.currentTarget.value)}
                        name="year"
                    >
                        <option value="" disabled>
                            YYYY
                        </option>
                        {years.map((y) => {
                            return (
                                <option key={y} value={`${y}`}>
                                    {y}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <GenderIconButtons gender={gender} setGender={setGender} />
                <ContinueButton
                    type="submit"
                    text={userState.step.buttonText}
                    onClick={() => {
                        userState.setLastName(lastName);
                        userState.setFirstName(firstName);
                    }}
                />
            </div>
        </form>
    );
};
