import { FormEvent, useState } from "react";
import { ContinueButton } from "../continue_button/continue_button";
import { useUserState } from "../../state/user_state";
import { validatePhone } from "../../helpers/phone_validation";
import styles from "./theme.module.css";
import { getThemeClass } from "../../helpers/theme_helper";
import { PhoneTcpa } from "../tcpa/phone_tcpa";
import { EmailInput } from "../email_input";

export const EmailPhoneForm = () => {
    const userState = useUserState();
    const [email, setEmail] = useState(userState.email || "");
    const [phone, setPhone] = useState(userState.phone || "");
    const [phoneAllowed, setPhoneAllowed] = useState(
        userState.phoneAllowed || false
    );
    const [phoneError, setPhoneError] = useState("");
    const submit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const validPhone = validatePhone(phone, setPhoneError);
        if (!validPhone) return;

        setPhoneError("");
        userState.setEmail(email);
        userState.setPhone(phone);
        userState.setPhoneAllowed(phoneAllowed);
        userState.nextStep();
    };

    return (
        <form onSubmit={submit} className={getThemeClass("form", styles)}>
            <div className="input-container">
                <h2 className="input-statement">
                    {userState.step.subHeader ??
                        "Almost done! Where do we send your free samples?"}
                </h2>
                <input
                    autoFocus
                    required
                    className={getThemeClass("input", styles)}
                    maxLength={11}
                    name="phone"
                    placeholder="Enter Phone Number"
                    value={phone}
                    type="tel"
                    onChange={(e) => setPhone(e.target.value)}
                ></input>
                <EmailInput setEmail={setEmail} email={email} styles={styles} />
                {phoneError && <p style={{ color: "red" }}>{phoneError}</p>}
                <PhoneTcpa
                    setPhoneAllowed={setPhoneAllowed}
                    phoneAllowed={phoneAllowed}
                    submitText={userState.step.buttonText}
                />
                <ContinueButton
                    type="submit"
                    text={userState.step.buttonText}
                    onClick={() => {}}
                />
            </div>
        </form>
    );
};
