import { useUserState } from "../../state/user_state";
import { ContinueButton } from "../continue_button/continue_button";

export const UnlockSamples = () => {
    const { step, nextStep } = useUserState((s) => ({
        step: s.step,
        nextStep: s.nextStep,
    }));

    return (
        <div className="text-center">
            <p className="text-xs mb-2">No Credit Card Needed</p>
            <p className="text-2xl">
                {step.header ?? `Who said good things can't come for free?`}
            </p>
            {step.subHeader == 'list' ? (
                <ul className="text-center w-90 list-none">
                    <li>&#x2713; No commitment upfront</li>
                    <li>&#x2713; Flexible work schedule</li>
                    <li>&#x2713; No experience required</li>
                </ul>
            ) : step.subHeader ?? ''}
            <div>
                <ContinueButton
                    onClick={nextStep}
                    text={step.buttonText ?? `I'll take it`}
                    type="button"
                />
            </div>
            <p className="italic text-sm mt-8 mb-16">One Per Household</p>
        </div>
    );
};
