import React, { FormEvent, useState } from "react";
import styles from "./combo_forms/theme.module.css";
import { useUserState } from "../state/user_state";
import { ContinueButton } from "./continue_button/continue_button";
import "./input.css";
import { OffsetBackground } from "./offset_background";
import { EmailInput } from "./email_input";
import { EmailTcpa } from "./tcpa/email_tcpa";

export const Email = () => {
    const { websiteConfig, stateEmail, nextStep, setStateEmail, step } =
        useUserState((s) => {
            return {
                websiteConfig: s.websiteConfig,
                stateEmail: s.email,
                nextStep: s.nextStep,
                setStateEmail: s.setEmail,
                step: s.step,
            };
        });
    const [email, setEmail] = useState(stateEmail || "");

    const submit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        nextStep();
    };

    return (
        <form onSubmit={submit}>
            <div className="input-container">
                <h2 className="input-statement">
                    {step.subHeader ?? "Enter an email for free items!"}
                </h2>

                <label htmlFor="email">E-mail:</label>
                <EmailInput
                    email={email}
                    setEmail={setEmail}
                    autofocus={true}
                    styles={styles}
                />
                <EmailTcpa />
                <ContinueButton
                    type="submit"
                    text={step.buttonText}
                    onClick={() => setStateEmail(email)}
                />
            </div>
            <OffsetBackground topSpacing="4rem"/>
        </form>
    );
};
