import { State } from "../state/user_state";
import { getTrackingSession } from "../tracking";
import {PublisherParams} from "../models/startup";

const DELIVERY_API = process.env.DELIVERY_API;

const age = (dob: string) => {
    if (!dob) {
        return 99;
    }

    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};

export const deliverLead = (
    offerId: string,
    userState: State,
    jornaya: string,
    pubParams: PublisherParams
) => {
    const userData = {
        email: userState.email,
        phone: userState.phone,
        address: userState.address1 || "",
        zipCode: userState.zipCode,
        state: userState.state,
        age: String(age(userState.getDateOfBirth() || "")),
        gender: userState.gender || "M",
        city: userState.city,
        firstName: userState.firstName,
        lastName: userState.lastName,
        timestamp: new Date(),
        // TODO: determine what this value should be
        // property_name: userState.propertyName,
        date_of_birth: userState.getDateOfBirth(),
        birth_month: userState.month,
        birth_day: userState.day,
        birth_year: userState.year,
        // TODO: Fill these out
        // device_type: userState.deviceType || "",
        // device_os: userState.deviceOS || "",
        trusted_form: userState.trustedFormUrl || "",
        jornaya: jornaya || userState.leadId || "", // Fallback to lead id for state
        utm_params: pubParams,
    };

    post(offerId, userData);
};

const post = (offerId: string, userData: any) => {
    return fetch(
        `${DELIVERY_API}/lead/${offerId}?token=${getTrackingSession()}`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(userData),
        }
    );
};
