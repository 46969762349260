import { useEffect } from "react";
import { State, Step, useUserState } from "../../state/user_state";
import { LinkOut, PivotApi } from "../../api/pivot";
import { getTrackingSession } from "../../tracking";
import {startupParameters} from "../../state/startup";

const canLoadLinkouts = (state: State) => {
    return (
        state.path.findIndex((p) => p.step === Step.LinkOut) !== -1 &&
        state.linkOuts === null &&
        state.firstName &&
        state.lastName &&
        state.email &&
        state.address1 &&
        state.city &&
        state.state &&
        state.zipCode &&
        state.phone &&
        state.gender &&
        state.getDateOfBirth() &&
        startupParameters.publisher.click_id &&
        startupParameters.publisher.utm_source
    );
};

export const LinkOutLoader = () => {
    const state = useUserState();
    const publisherSource = startupParameters.publisher;

    useEffect(() => {
        const run = async () => {
            let loadedLinkOuts = [] as LinkOut[];
            try {
                loadedLinkOuts = await PivotApi.getLinkOuts(
                    state.websiteConfig!.pivotLinkOutUrl,
                    {
                        step: "linkout",
                        user_data: {
                            firstName: state.firstName!,
                            lastName: state.lastName!,
                            email: state.email!,
                            address: state.address1!,
                            address2: state.address2!,
                            city: state.city!,
                            state: state.state!,
                            zipCode: state.zipCode!,
                            phone: state.phone!,
                            gender: state.gender!,
                            psfp: state.psfp || "",
                            dob: state.getDateOfBirth()!,
                            country: "USA",
                            session: getTrackingSession(),
                            utm_params: {
                                ...publisherSource,
                                click_id: startupParameters.publisher.click_id || "",
                            },
                        },
                    },
                    state.pathConfig?.numOffers || 2
                );
            } catch (err) {}
            state.setLinkOuts(loadedLinkOuts || []);
        };
        if (canLoadLinkouts(state) && state.step.step == Step.LinkOut) {
            run();
        } else if (state.step.step == Step.LinkOut) {
            state.setLinkOuts([]);
        }
    }, [
        state.firstName,
        state.lastName,
        state.email,
        state.address1,
        state.city,
        state.state,
        state.zipCode,
        state.phone,
        state.gender,
        state.step,
    ]);

    return null;
};
