import React from "react";

import "./disclaimer.css";
import { Step, useUserState } from "../state/user_state";

export const Disclaimer = () => {
    const { step } = useUserState((s) => s.step);
    const websiteConfig = useUserState((s) => s.websiteConfig);

    if (step !== Step.Unlock) {
        return null;
    }
    return (
        <div className="pb-12 disclaimer-container">
            <p className="disclaimer pb-8">
                <strong>NO PURCHASE NECESSARY.</strong> Void where prohibited.
                For full official rules go to{" "}
                <a
                    href={`${websiteConfig?.siteBaseUrl}/official-rules`}
                    target="_blank"
                    className="color-white hover:underline"
                >
                    Official Rules.
                </a>
            </p>
        </div>
    );
};
