import React, { useMemo } from "react";
import { useUserState } from "../../state/user_state";

export const EmailTcpa = () => {
    const { websiteConfig, tcpa } = useUserState((s) => ({
        websiteConfig: s.websiteConfig,
        tcpa: s.domainConfig?.tcpa?.email
    }));
    const cleanTcpa = useMemo(() => {
        if (!tcpa) {
            return ""
        }
        const parser = new DOMParser();
        const doc = parser.parseFromString(tcpa, "text/html");
        const text = doc.body.textContent?.trim();
        return text ? tcpa : ""
    }, [tcpa]);
    return <div className="disclaimer-email">
        {cleanTcpa ? <p dangerouslySetInnerHTML={{ __html: cleanTcpa }}/> : <p>
            By entering you agree you've read and will be bound to the{" "}
            <a
                href={`${websiteConfig?.siteBaseUrl}/official-rules`}
                target="_blank"
                className="hover:underline"
            >
                Official Rules
            </a>
            . By clicking continue, I agree to receive email offers and
            newsletters from {websiteConfig?.siteName} and
            LivingLargeSweeps, and agree to the{" "}
            <a
                className="color-white no-underline hover:underline"
                href={`${websiteConfig?.siteBaseUrl}/terms-conditions`}
                target="_blank"
            >
                Terms
            </a>{" "}
            and{" "}
            <a
                className="color-white no-underline hover:underline"
                href={`${websiteConfig?.siteBaseUrl}/privacy-policy`}
                target="_blank"
            >
                Privacy Policy
            </a>{" "}
            and site visit recordation by TrustedForm and Jornaya.
        </p>}
    </div>
}