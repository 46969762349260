import { SDPS } from "../api/sdps";
import { State } from "../state/user_state";

export const updateSDPSUser = async (state: State) => {
    if (state.phone && state.websiteConfig && state.domainConfig) {
        await SDPS.smsSubscribe(
            SDPS.buildSmsPayload(
                state,
                state.websiteConfig?.websiteApiKey,
                state.domainConfig?.sdps_opt_in_key
            ),
            state.websiteConfig?.pushnamiApiBaseUrl
        );
    }

    if (state.email && state.websiteConfig && state.pathConfig) {
        await SDPS.emailSubscribe(
            SDPS.buildEmailPayload(
                state,
                state.websiteConfig?.dataWebsiteApiKey,
                state.pathConfig.primaryPlacement
            ),
            state.websiteConfig.pushnamiApiBaseUrl
        );
    }
};
