import { useEffect } from "react";
import { OfferConfig } from "../models/config";
import {useUserState} from "./user_state";
import {startupParameters} from "./startup";

let loading = false;
export const useOfferConfig = (): OfferConfig | null => {
    const { offerConfig, setOfferConfig } = useUserState((s) => {
        return {
            offerConfig: s.offerConfig,
            setOfferConfig: s.setOfferConfig,
        };
    });

    useEffect(() => {
        if (offerConfig || loading) return;
        loading = true;
        const fetchConfig = async () => {
            try {
                const response = await fetch(
                    `${startupParameters.cdn.manifest}/${startupParameters.path.rewardId}/manifest.json`
                );
                const data = await response.json();
                setOfferConfig(data);
            } catch (error) {
                console.error("Failed to fetch config:", error);
            }
        };

        fetchConfig();
    }, []);

    return offerConfig;
};
