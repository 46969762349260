import { FormEvent, useState } from "react";
import { Step, useUserState } from "../state/user_state";
import React from "react";

import { ContinueButton } from "./continue_button/continue_button";
import "./input.css";
import "./date_of_birth.css";
import { OffsetBackground } from "./offset_background";

export const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

export function getDaysInMonth(year: number): number[] {
    const daysInMonth = [
        31, // January
        year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0) ? 29 : 28, // February (leap year check)
        31, // March
        30, // April
        31, // May
        30, // June
        31, // July
        31, // August
        30, // September
        31, // October
        30, // November
        31, // December
    ];

    return daysInMonth;
}
export function generateYearList(currentYear: number): number[] {
    const years: number[] = [];

    for (let i = currentYear - 100; i <= currentYear - 18; i++) {
        years.push(i);
    }

    return years;
}

export const years = generateYearList(new Date().getFullYear());
years.sort((a, b) => b - a);

export const DateOfBirth = () => {
    const userState = useUserState();

    const [month, setMonth] = useState(userState.month || "");
    const [year, setYear] = useState(userState.year || "");
    const [day, setDay] = useState(userState.day || "");

    const submit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        userState.setMonth(month);
        userState.setYear(year);
        userState.setDay(day);
        userState.nextStep();
    };

    const daysPerMonth = getDaysInMonth(year ? parseInt(year) : years[0]);
    const days =
        months.indexOf(month) != -1 ? daysPerMonth[months.indexOf(month)] : 31;

    return (
        <form onSubmit={submit}>
            <div className="input-container">
                <h2 className="input-statement">
                    {userState.step.subHeader ??
                        "Like birthday gifts? Confirm below."}
                </h2>

                <label htmlFor="address1">Date of Birth:</label>
                <div className="date-of-birth-container">
                    <select
                        required
                        onChange={(e) => setMonth(e.currentTarget.value)}
                        value={month ? parseInt(month) : ""}
                        autoComplete="bday-month"
                        name="month"
                    >
                        <option value="" disabled>
                            Month
                        </option>
                        {months.map((m) => {
                            const monthNumber = months.indexOf(m) + 1;
                            return (
                                <option key={m} value={monthNumber}>
                                    {m}
                                </option>
                            );
                        })}
                    </select>
                    <select
                        required
                        autoComplete="bday-day"
                        value={day ? day : ""}
                        onChange={(e) => setDay(e.currentTarget.value)}
                        name="day"
                    >
                        <option value="" disabled>
                            Day
                        </option>
                        {Array.from(
                            { length: days },
                            (_, index) => index + 1
                        ).map((v) => {
                            return (
                                <option key={v} value={`${v}`}>
                                    {v}
                                </option>
                            );
                        })}
                    </select>
                    <select
                        required
                        autoComplete="bday-year"
                        value={year}
                        onChange={(e) => setYear(e.currentTarget.value)}
                        name="year"
                    >
                        <option value="" disabled>
                            Year
                        </option>
                        {years.map((y) => {
                            return (
                                <option key={y} value={`${y}`}>
                                    {y}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <ContinueButton
                    type="submit"
                    text={userState.step.buttonText}
                    onClick={() => {}}
                />
            </div>
            <OffsetBackground topSpacing="4rem" />
        </form>
    );
};
