import { useUserState } from "../../state/user_state";
import { ContinueButton } from "../continue_button/continue_button";
import { useWatchOfferWallRender } from "./useWatchOfferWallRender";
import { ListSkeleton } from "../../steps/list_skeleton";
import { trackOfferWall } from "../../tracking/offer_wall";
import { EventType } from "@pushnami/models-pivot-tracking/dist/models";
import { useOfferwallPlacement } from "./useOfferwallPlacement";
import { useEffect } from "react";
import { Stage, trackUserProgress } from "../../tracking/user";
import {startupParameters} from "../../state/startup";

const NoThanksButton = () => {
    return (
        <div className="flex justify-center items-center">
            <span className="text-white font-bold">NO THANKS</span>
            <span className="text-white opacity-50 text-5xl ml-1 flex items-center pb-2">
                &rsaquo;
            </span>
            <span className="text-white opacity-100 text-5xl -ml-2 flex items-center pb-2">
                &rsaquo;
            </span>
        </div>
    );
};

export const OfferWallPlacement = () => {
    const { nextStep } = useUserState();
    const { utm_source, utm_content, utm_term } = startupParameters.publisher;
    const { email, zipCode } = useUserState(
        ({ email, firstName, gender, zipCode }) => ({
            email,
            firstName,
            gender,
            zipCode,
        })
    );
    const { ref: offerWallRef, loading } = useWatchOfferWallRender();
    useOfferwallPlacement(offerWallRef);

    useEffect(() => {
        trackUserProgress(Stage.OfferWall);
    }, []);

    return (
        <>
            <div className="bg-white text-center m-4 p-4 rounded shadow-lg ">
                <h1 className="text-xl font-bold text-gray-800">
                    Last Step Before Revealing Winner Status!
                </h1>
                <p className="text-md text-gray-600">
                    Please complete an offer to continue
                </p>
            </div>
            <div className="flex-col">
                <ListSkeleton rows={6} show={loading} />
            </div>

            <div id="offerwall-container" ref={offerWallRef as any}></div>

            {!loading ? (
                <div className="flex justify-center">
                    <ContinueButton
                        onClick={() => {
                            nextStep();
                            trackOfferWall(EventType.OfferWallSkip, {
                                utm_source,
                                utm_content,
                                utm_term,
                                email,
                                zipCode,
                            });
                        }}
                        type={"submit"}
                        text={<NoThanksButton />}
                    />
                </div>
            ) : null}
        </>
    );
};
