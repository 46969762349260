import React, { FormEvent, useState } from "react";
import styles from "../combo_forms/theme.module.css";
import { useUserState } from "../../state/user_state";
import { ContinueButton } from "../continue_button/continue_button";
import "../input.css";
import { SubHeaderTemplated } from "../sub_header/sub_header_templated";
import { EmailInput } from "../email_input";

export const EmailV2 = () => {
    const { websiteConfig, stateEmail, nextStep, setStateEmail, step } =
        useUserState((s) => {
            return {
                websiteConfig: s.websiteConfig,
                stateEmail: s.email,
                nextStep: s.nextStep,
                setStateEmail: s.setEmail,
                step: s.step,
            };
        });
    const [email, setEmail] = useState(stateEmail || "");

    const submit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        nextStep();
    };

    return (
        <div className="">
            <form onSubmit={submit}>
                <div className="input-container">
                    <SubHeaderTemplated baseText="Enter an email for free items!" />

                    <label htmlFor="email">E-mail:</label>
                    <EmailInput
                        setEmail={setEmail}
                        email={email}
                        autofocus={true}
                        styles={styles}
                    />
                    <p className="disclaimer-email">
                        By entering you agree you've read and will be bound to
                        the{" "}
                        <a
                            href={`${websiteConfig?.siteBaseUrl}/official-rules`}
                            target="_blank"
                            className="hover:underline"
                        >
                            Official Rules
                        </a>
                        . By clicking continue, I agree to receive email offers
                        and newsletters from {websiteConfig?.siteName} and
                        LivingLargeSweeps, and agree to the{" "}
                        <a
                            className="color-white no-underline hover:underline"
                            href={`${websiteConfig?.siteBaseUrl}/terms-conditions`}
                            target="_blank"
                        >
                            Terms
                        </a>{" "}
                        and{" "}
                        <a
                            className="color-white no-underline hover:underline"
                            href={`${websiteConfig?.siteBaseUrl}/privacy-policy`}
                            target="_blank"
                        >
                            Privacy Policy
                        </a>{" "}
                        and site visit recordation by TrustedForm and Jornaya.
                    </p>
                    <ContinueButton
                        type="submit"
                        text={step.buttonText}
                        onClick={() => setStateEmail(email)}
                    />
                </div>
            </form>
        </div>
    );
};
