import { useEffect, useRef, useState } from "react";
import { useUserState } from "../state/user_state";
import { md5 } from "js-md5";

export const Neustar = () => {
    const loaded = useRef<boolean>(false);
    const websiteConfig = useUserState((s) => s.websiteConfig);
    useEffect(() => {
        if (loaded.current || !websiteConfig?.neustar || document.getElementById("neustar-code")) {
            console.debug(websiteConfig?.neustar ? "already loading" : "missing config")
            return;
        }

        loaded.current = true;
        const script = document.createElement("script");
        script.id = "neustar-code";
        script.src = `https://aa.agkn.com/adscores/r.js?sid=${websiteConfig.neustar}`;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, [websiteConfig]);

    const state = useUserState();
    const [lastTracked, setLastTracked] = useState("");
    useEffect(() => {
        if (!state.email || !websiteConfig?.neustar || lastTracked === state.email) {
            return;
        }
        setLastTracked(state.email);
        const existing = document.getElementById("neustar-code-email") as HTMLScriptElement;
        if (existing) {
            existing.src = `https://aa.agkn.com/adscores/s.js?sid=${websiteConfig.neustar}&em=${md5(state.email)}`;
            return;
        }
        const script = document.createElement("script");
        script.id = "neustar-code-email";
        script.type = "text/javascript";
        script.src = `https://aa.agkn.com/adscores/s.js?sid=${websiteConfig.neustar}&em=${md5(state.email)}`;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, [state]);
    return null;
};
