import {OrderProgress} from "../../components/order_progress/order_progress";
import {AlmostThere} from "../../components/path_incentives/almost_there";
import {CongratsMessage} from "../../components/path_incentives/congrats_message";
import {SamplesRewardImage} from "../../components/reward_image/samples_reward_image";
import {ZeetoJS} from "../../components/zeeto/zeeto_js";
import {Path} from "../../path";
import {Step, useUserState} from "../../state/user_state";

const classesForStep = (step: Step) => {
    switch (step) {
        case Step.Unlock:
        case Step.UnlockSamples:
            return "";
        case Step.Path:
        case Step.PathSecondary:
            return "shadow-lg bg-white rounded-md border-gray-50 mt-4 sm:w-2/5 w-[95%] min-h-[360px]";
    }
    return "shadow-lg p-4 bg-white rounded-md border-gray-50 mt-4 sm:w-3/5 w-[95%] min-h-[360px]";
};

export const SamplesLayout = (config: {
    onComplete: () => unknown;
}) => {
    const step = useUserState((s) => s.step);
    const classesForContainer = classesForStep(step.step);
    return (
        <>
            <OrderProgress/>
            <SamplesRewardImage/>
            <AlmostThere/>
            <CongratsMessage/>
            <div className={classesForContainer}>
                <Path onComplete={config.onComplete}/>
                <ZeetoJS/>
            </div>
        </>
    );
};
