import { EventType } from "@pushnami/models-pivot-tracking/dist/models";
import { sleep, waitForSessionToken } from "./helper";

export const trackOfferClick = async (
    offerId: string,
    creativeId: string,
    metadata: string[] = [],
    explore: boolean,
    algorithm: string
) => {
    if (!offerId || !creativeId) return;

    await waitForSessionToken();
    await window.track(EventType.OfferClick, {
        offer_id: offerId,
        creative_id: creativeId,
        answer_metadata: metadata.join(","),
        explore,
        algorithm
    });
};

let nextImpression: Record<string, any> | null = null;
document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible' && nextImpression) {
        console.log('tracking return impression', nextImpression)
        window.track(EventType.OfferImpression, nextImpression);
        nextImpression = null;
    }
});

/**
 * Impressions require awaiting a document update before checking visibility. This means that 
 * the system can say it is showing a new creative while the user is navigating away from the
 * path due to a link out offer. By adding a small sleep we allow JS document to update it's
 * visibility prior to tracking an impression. We listen above on the visibilitychange to 
 * determine when we should track the next impression within the path.
 */
export const trackOfferImpression = async (
    offerId: string,
    creativeId: string,
    explore: boolean,
    algorithm: string
) => {
    if (!offerId || !creativeId) return
    await waitForSessionToken();
    await sleep(500);
    if (document.visibilityState == 'hidden'){
        nextImpression = {
            offer_id: offerId,
            creative_id: creativeId,
            explore,
            algorithm
        }
    } else {
        window.track(EventType.OfferImpression, {
            offer_id: offerId,
            creative_id: creativeId,
            explore,
            algorithm
        });
    }
};

export const trackOfferSkip = async (
    offerId: string,
    creativeId: string,
    explore: boolean,
    algorithm: string
) => {
    if (!offerId || !creativeId) return

    await waitForSessionToken();
    await window.track(EventType.OfferSkip, {
        offer_id: offerId,
        creative_id: creativeId,
        explore,
        algorithm
    });
};

export const trackOfferTcpa = async (offerId?: string, jornaya?: string) => {
    if (!offerId || !jornaya) return;

    await waitForSessionToken();
    await window.track(EventType.TCPAOfferData, { offer_id: offerId, jornaya });
};

export const trackQualifierAnswer = async (offerId: string, questionId: string, answerId: string, answer: string, psfp: string, email: string, phone: string) => {
    await waitForSessionToken();
    await window.track('qualifier_question_answer' as any, {
        offer_id: offerId,
        question_id: questionId,
        answer_id: answerId,
        answer,
        psfp,
        email,
        phone
    })
}