import { OfferConfig } from "../../models/config";
import { useUserState } from "../../state/user_state";

const splitText = (text: string) => {
    if (text.includes("{promo_text")) return text.split("{promo_text}");

    if (text.includes("{promo_amount}")) return text.split("{promo_amount}");
    return [];
};

const getReplacedText = (
    text: string,
    offerConfig: OfferConfig | null
): string => {
    if (!offerConfig) return "your chance to win!";

    if (text.includes("{promo_text")) return offerConfig.promoText || "";

    if (text.includes("{promo_amount}")) return offerConfig.promoAmount || "";

    return "";
};

export const SubHeaderTemplated = ({ baseText }: { baseText: string }) => {
    const userState = useUserState();

    const promoText = userState.offerConfig?.promoText ?? "gift card";
    let text = userState.step && userState.step.subHeader;
    const parts = text && splitText(text);

    if (parts && parts?.length === 2) {
        return (
            <h2 className="input-statement text-center text-lg mt-1">
                {parts[0]}{" "}
                <span className="font-bold">
                    {getReplacedText(text || "", userState.offerConfig)}
                </span>{" "}
                {parts[1]}
            </h2>
        );
    }

    return (
        <h2 className="input-statement text-center text-xl font-bold mt-1">
            {text ?? baseText}
        </h2>
    );
};
