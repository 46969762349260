import React from "react";
import { createRoot } from "react-dom/client";
import Pivot, { OnCompleteData } from "./pivot";
import { loadFromAttributes, startupParameters } from "./state/startup";

declare global {
    interface Window {
        pivot: {
            setup: (element: HTMLElement) => void;
        };
    }
}

(() => {
    const loadStyles = (container: Node) => {
        const pivotStyles = document.createElement("link");
        pivotStyles.rel = "stylesheet";
        pivotStyles.href = startupParameters.cdn.styles;
        container.appendChild(pivotStyles);

        const fonts = document.createElement("link");
        fonts.rel = "stylesheet";
        fonts.href =
            "https://fonts.googleapis.com/css2?family=Open+Sans&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
        container.appendChild(fonts);
    };

    const loadUI = (
        container: HTMLElement,
        domain: string | null,
        onComplete: (userData: OnCompleteData) => void
    ) => {
        if (!domain) {
            throw new Error("domain attribute is required");
        }
        const root = createRoot(container);
        root.render(<Pivot domainId={domain} onComplete={onComplete} />);
    };

    window.pivot = {
        setup: (element: HTMLElement) => {
            loadFromAttributes(element);
            loadStyles(document.head);
            loadUI(element, element.getAttribute("domain"), (userData) =>
                element.dispatchEvent(
                    new CustomEvent<OnCompleteData>("complete", {
                        detail: userData,
                    })
                )
            );
        },
    };

    window.customElements.define(
        "pivot-ui",
        class extends HTMLElement {
            constructor() {
                super();
                const shadow = this.attachShadow({ mode: "closed" });
                loadFromAttributes(this);
                loadStyles(shadow);
                this.stylesPolyfill();
                const container = document.createElement("div");
                loadUI(container, this.getAttribute("domain"), (userData) =>
                    this.dispatchEvent(
                        new CustomEvent<OnCompleteData>("complete", {
                            detail: userData,
                        })
                    )
                );
                shadow.appendChild(container);
            }

            stylesPolyfill() {
                if (document.head.querySelector("#pivot-ui")) {
                    return;
                }
                // TODO: figure out a way to DRY this from lty.css
                const themeVars = `
            :root {
                --lty-color-accent: #EF6C00;
                --lty-color-accent-inverse: #fff;
                --lty-color-label: #6B6B6B;
                --lty-color-border: #E5E5E5;
            }
            `;
                const style = document.createElement("style");
                style.id = "pivot-ui";
                style.textContent = `
                .hidden { 
                    display: none;
                }
                ${themeVars}                
            `;
                document.head.appendChild(style);

                // https://github.com/google/material-design-icons/issues/1165#issuecomment-851128010
                const fonts = document.createElement("link");
                fonts.rel = "stylesheet";
                fonts.href =
                    "https://fonts.googleapis.com/css2?family=Open+Sans&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
                document.head.appendChild(fonts);
            }
        }
    );
    console.log("cdn", startupParameters.cdn.origin);
})();
