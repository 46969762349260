import "./image.css";
import "./reward.css";
import { Step, useUserState } from "../../state/user_state";
import { useOfferConfig } from "../../state/offer_config";
import styles from "./theme.module.css";
import { getThemeClass } from "../../helpers/theme_helper";
import { startupParameters } from "../../state/startup";

export const RewardImage = () => {
    const { step, nextStep } = useUserState();
    const offerConfig = useOfferConfig();

    const onClick = () => {
        switch (step.step) {
            case Step.Unlock:
            case Step.UnlockSamples:
                nextStep();
                return;
        }
    };

    if (
        step.step === Step.Path ||
        step.step === Step.Reward ||
        step.step === Step.PathSecondary ||
        step.step === Step.LinkOut ||
        step.showReward === false
    ) {
        return null;
    }

    const extraImageClass =
        step.step === Step.Unlock ? "" : "reward-image-small";

    const imageThemeClass = getThemeClass("image", styles);
    const imageContainerClass = getThemeClass("container", styles);
    const image = offerConfig?.rewardImageUri
        ? `${startupParameters.cdn.origin}${offerConfig?.rewardImageUri || ""}`
        : startupParameters.ux.logo;
    return (
        <div
            className={`max-w-[90%] mx-auto z-20 ${imageContainerClass}`}
            onClick={onClick}
        >
            <img
                src={`${startupParameters.cdn.origin}${
                    offerConfig?.rewardImageUri || ""
                }`}
                alt=""
                loading="lazy"
                className={`img-responsive ${extraImageClass} ${imageThemeClass}`}
            ></img>
        </div>
    );
};
