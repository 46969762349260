import {ContinueButton} from "../../components/continue_button/continue_button";
import "../../components/input.css";
import {useUserState} from "../../state/user_state";
import {OffsetBackground} from "../../components/offset_background";

export const Sweeps = () => {
    const nextStep = useUserState((s) => s.nextStep);
    const step = useUserState((s) => s.step);
    return (
        <div>
            <div className="input-container">
                <ContinueButton
                    onClick={() => {
                        nextStep();
                    }}
                    type="button"
                    text={step.buttonText ?? "Unlock my play!"}
                />
            </div>
            <OffsetBackground topSpacing="20%"/>
        </div>
    );
};