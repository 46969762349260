/**
 * @param dateString
 * @returns The date string in { year, month, day } format or null if conversion fails.
 */
export const convertDateFormat = (dateString: string):  { year: string, month: string, day: string } | null => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return null;

    const year = date.getFullYear().toString();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return {
        year,
        month,
        day
    };
}