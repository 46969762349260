import {useEffect, useState} from "react";
import {FlipCard} from "../components/game/flip_card";
import {useUserState} from "../state/user_state";
import "./reward_step.css";
import {RewardApi} from "../api/reward";
import {WinView} from "../components/game/win";
import {Lose} from "../components/game/lose";
import {startupParameters} from "../state/startup";

export const Reward = (config: {
    onComplete: () => unknown;
}) => {
    const state = useUserState();
    const [loading, setLoading] = useState(true);
    const [win, setWin] = useState(false);
    const [lastImageUri, setLastImageUri] = useState(
        `${startupParameters.cdn.origin}/image/card-game-lose.png`
    );
    const [flippedCards, setFlippedCards] = useState(0);
    useEffect(() => {
        async function checkWinner() {
            try {
                const result = await RewardApi.check(
                    state.offerConfig!.rewardCheckUrl,
                    state.offerConfig!.rewardId,
                    state.firstName,
                    state.lastName,
                    state.email,
                    state.address1,
                    state.address2,
                    state.city,
                    state.state,
                    state.zipCode,
                    state.phone,
                    state.psfp
                );
                if (result.win) {
                    setLastImageUri(`${startupParameters.cdn.origin}${state.offerConfig!.rewardImageUri || ''}`);
                    setWin(true);
                }
            } catch (err) {
            } finally {
                setLoading(false);
            }
        }

        checkWinner();
    }, []);

    if (loading) {
        return (
            <div className="spinner-container">
                <div className="spinner"/>
            </div>
        );
    }

    const winOverlay = win && flippedCards >= 3;
    const loseOverlay = !win && flippedCards >= 3;
    return (
        <div className="reward-outer-container bg-white p-4 rounded-md">
            <div className="text-container">
                <p className="instruction-header">
                    {state.firstName} Lets Play!
                </p>
            </div>
            <div className="text-container">
                <p className="instructions">
                    Tap each card to flip! Match 3 cards with the same image and
                    win!
                </p>
            </div>
            <div className="reward-container">
                <FlipCard
                    backImage={`${startupParameters.cdn.origin}${state.offerConfig?.rewardImageUri || ''}`}
                    onFlip={() => setFlippedCards(flippedCards + 1)}
                />
                <FlipCard
                    backImage={`${startupParameters.cdn.origin}${state.offerConfig?.rewardImageUri || ''}`}
                    onFlip={() => setFlippedCards(flippedCards + 1)}
                />
                <FlipCard
                    backImage={lastImageUri}
                    onFlip={() => setFlippedCards(flippedCards + 1)}
                />
            </div>
            {winOverlay ? <WinView onComplete={config.onComplete}/> : null}
            {loseOverlay ? <Lose onComplete={config.onComplete}/> : null}
        </div>
    );
};
