import { FormEvent, useState, useEffect } from "react";
import { useUserState } from "../../state/user_state";

export const QuizForm = () => {
    const userState = useUserState();
    const [key, setKey] = useState(0);
    const [quiz, setQuiz] = useState(() => userState.quizzes?.find(quiz => !quiz.answer));

    useEffect(() => {
        setQuiz(userState.quizzes?.find(q => !q.answer));
    }, [key, userState.quizzes]);

    useEffect(() => {
        if (!quiz) {
            userState.nextStep();
        }
    }, [quiz, userState]);

    const reloadComponent = () => {
        setKey(prevKey => prevKey + 1);
    };

    if (!quiz) {
        return null;
    }

    const submit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        userState.setQuiz(quiz);
        reloadComponent();
    };

    return (
        <form onSubmit={submit} key={key} className="form">
            <div className="input-container">
                <h2 className="input-statement">
                    {quiz.question ?? ""}
                </h2>
                {quiz.options.map((option, index) => (
                    <button
                        className={`continue-button`}
                        key={index}
                        type="submit"
                        onClick={() => setQuiz({...quiz, answer: quiz.options[index]})}
                    >
                        {option ?? "Submit"}
                    </button>
                ))}
            </div>
        </form>
    );
};
