import { useEffect, useState } from "react";
import { LoadingBar } from "../ui_elements/loading_bar";
import { Step, useUserState } from "../../state/user_state";

export const LookingForOffersV1 = () => {
    const state = useUserState();
    useEffect(() => {
        setTimeout(() => {
            if (state.step.step === Step.LookingForOffersV1) {
                state.nextStep();
            }
        }, 3650);
    }, [state.step, state.nextStep]);
    return (
        <div className="flex flex-col flex-1 justify-around">
            <div className="font-bold text-2xl app-font text-center mx-8">
                Matching you to your top free offers!
            </div>
            <div className="mx-auto w-[30%]">
                <img src="/image/loading_offers_v1.png" />
            </div>
            <div className="mx-12">
                <LoadingBar />
            </div>
        </div>
    );
};
