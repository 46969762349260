import { Theme, useTheme } from "../state/theme";

export const getThemeClass = (className: string, styles: any) => {
    const theme = useTheme();
    switch (theme) {
        case Theme.SAMPLES:
            return styles[`samples-${className}`];
        case Theme.SWEEPS:
            return styles[`sweeps-${className}`];
        case Theme.AIO:
            return styles[`aio-${className}`];
        case Theme.LARGE_BUTTON:
            return styles[`lgbtn-${className}`];
        case Theme.RED_BUTTONS:
            return styles[`redbtn-${className}`];
        case Theme.LOTTERY:
            return styles[`lty-${className}`];
        case Theme.INFO:
            return styles[`inf-${className}`]
    }
    return "";
};
