import { useEffect, useRef } from "react";
import { useUserState } from "../state/user_state";

export const TrustedCert = () => {
    const loaded = useRef<boolean>(false);
    const userState = useUserState();
    useEffect(() => {
        if (loaded.current) {
            return;
        }
        loaded.current = true;

        (window as any).trustedFormCertUrlCallback = (url: string) => {
            userState.setTrustedFormUrl(url);
        };
    }, []);
    return null;
};
