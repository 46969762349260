import { useState, useEffect, useMemo } from "react";
import { useUserState } from "../../state/user_state";
import { Loading } from "../../steps/loading";
import { LinkOutTextCreative } from "./linkout_text_creative";
import { LinkOutImageCreative } from "./linkout_image_creative";
import { LinkOut } from "../../api/pivot";
import { SingleOptInCreativeComponent } from "../single_opt_in/soi_creative";
import { trackOfferImpression, trackOfferSkip } from "../../tracking/offers";
import { QualifierCreative } from "../qualifier/qualifier";

export interface LinkOutViewComponentProps {
    currentLinkOut: LinkOut;
    onContinue: () => void;
    onSkip: () => void;
}

export const LinkOutView = () => {
    const state = useUserState();
    const [linkOutIndex, setLinkOutIndex] = useState(0);

    const nextLinkOut = () => {
        setLinkOutIndex((prevIndex) => prevIndex + 1);
    };

    useEffect(() => {
        if (
            state.linkOuts !== null &&
            (state.linkOuts.length === 0 ||
                linkOutIndex >= state.linkOuts.length ||
                !state.linkOuts[linkOutIndex])
        ) {
            state.nextStep();
        }
    }, [state.linkOuts, linkOutIndex]);

    const { currLinkOut, qualifierToShow } = useMemo(() => {
        if (state.linkOuts == null || state.linkOuts[linkOutIndex] == null)
            return { currLinkOut: null, qualifierToShow: null };
        const currLinkOut = state.linkOuts[linkOutIndex];
        if (!currLinkOut.qualifiers || currLinkOut.qualifiers.length == 0)
            return { currLinkOut, qualifierToShow: null };

        if (!state.metadataKeys)
            return { currLinkOut, qualifierToShow: currLinkOut.qualifiers[0] };

        return {
            currLinkOut,
            qualifierToShow: currLinkOut.qualifiers.find(
                (q) =>
                    state.metadataKeys?.findIndex((mk) =>
                        mk.includes(q.attribute)
                    ) == -1
            ),
        };
    }, [state.metadataKeys, linkOutIndex, state.linkOuts]);

    useEffect(() => {
        if (state.linkOuts == null) return;

        const linkout = state.linkOuts[linkOutIndex];
        if (!Boolean(linkout)) return;

        if (qualifierToShow) return;

        if (linkout.qualifiers && linkout.qualifiers.length > 0) {
            const userQualifies = linkout.qualifiers.every((q) => {
                return state.metadataKeys?.some((k) => {
                    return q.qualified_values.some((qv) => {
                        return k === `${q.attribute}:${qv}`;
                    });
                });
            });
            if (!userQualifies) {
                nextLinkOut();
                return;
            }
        }

        trackOfferImpression(
            linkout.offer_id,
            linkout?.soi_creative?.id ||
                linkout.linkout_creative?.creative?.id ||
                "",
            linkout.explore,
            linkout.alg
        );
    }, [
        state.metadataKeys,
        linkOutIndex,
        state.linkOuts,
        qualifierToShow,
        nextLinkOut,
    ]);

    if (state.linkOuts == null) {
        return (
            <div className="bg-white p-4 rounded-md min-h-96 my-auto flex">
                <Loading />
            </div>
        );
    }

    if (state.linkOuts == null) {
        return (
            <div className="bg-white p-4 rounded-md min-h-96 my-auto justify-center flex min-w-[400px] w-[40%] mx-4">
                <Loading />
            </div>
        );
    }

    if (!currLinkOut) {
        return null; // In case the nextStep hasn't been triggered yet
    }

    const skipOffer = () => {
        trackOfferSkip(
            currLinkOut.offer_id,
            currLinkOut?.soi_creative?.id ||
                currLinkOut.linkout_creative?.creative?.id ||
                "",
            currLinkOut.explore,
            currLinkOut.alg
        );
        setLinkOutIndex((prevIndex) => prevIndex + 1);
    };

    if (qualifierToShow) {
        return (
            <QualifierCreative
                qualifier={qualifierToShow}
                offerId={currLinkOut.offer_id}
            />
        );
    }

    if (currLinkOut.soi_creative) {
        return (
            <SingleOptInCreativeComponent
                usePathStyle={true}
                creative={currLinkOut.soi_creative}
                nextCreative={nextLinkOut}
                explore={currLinkOut.explore}
                alg={currLinkOut.alg}
            />
        );
    }

    if (
        currLinkOut.linkout_creative?.creative.header &&
        currLinkOut.linkout_creative?.creative.header !== ""
    ) {
        return (
            <LinkOutTextCreative
                currentLinkOut={currLinkOut}
                onContinue={nextLinkOut}
                onSkip={skipOffer}
            />
        );
    }

    return (
        <LinkOutImageCreative
            currentLinkOut={currLinkOut}
            onContinue={nextLinkOut}
            onSkip={skipOffer}
        />
    );
};
