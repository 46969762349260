import { Theme, useTheme } from "../../state/theme";
import { AIOLayout } from "../aio/aio_layout";
import { SamplesLayout } from "../samples/samples_layout";
import { SweepsLayout } from "../sweeps/sweeps_layout";
import { LargeButtonLayout } from "../lgbtn/large_button_layout";
import { LotteryLayout } from "../lty/lty_layout";
import { InfoLayout } from "../info/info_layout";

export const Body = (config: { onComplete: () => unknown }) => {
    const theme = useTheme();
    switch (theme) {
        case Theme.SAMPLES:
            return <SamplesLayout onComplete={config.onComplete} />;
        case Theme.AIO:
            return <AIOLayout onComplete={config.onComplete} />;
        case Theme.LARGE_BUTTON:
            return <LargeButtonLayout onComplete={config.onComplete} />;
        case Theme.LOTTERY:
            return <LotteryLayout onComplete={config.onComplete} />;
        case Theme.INFO:
            return <InfoLayout onComplete={config.onComplete} />;
        default:
            return <SweepsLayout onComplete={config.onComplete} />;
    }
};
