

export const ListSkeleton = ({show, rows}: {show: boolean, rows: number}) => {
    return (<>
    {show ? 
    <div role="status" className="p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded-sm shadow-sm animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700">
    {[...Array(rows)].map((_, index) => (
        <div key={index} className="flex items-center justify-between pt-4">
            <div className="flex-1">
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-3/4"></div>
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-1/6"></div>
        </div>
    ))}
    <span className="sr-only">Loading...</span>
</div>
    : null}
    </>


    )
}