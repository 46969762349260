interface RewardResult {
    win: boolean;
    link?: string;
}

export const RewardApi = {
    check: async (
        url: string,
        reward: string,
        firstName: string | null,
        lastName: string | null,
        email: string | null,
        address: string | null,
        address2: string | null,
        city: string | null,
        state: string | null,
        zip: string | null,
        phone: string | null,
        psfp: string | null
    ): Promise<RewardResult> => {
        const response = await fetch(
            url +
                `?reward=${reward}&firstname=${firstName}&lastname=${lastName}&email=${encodeURIComponent(
                    email || ""
                )}&address=${encodeURIComponent(
                    address || ""
                )}&address2=${encodeURIComponent(
                    address2 || ""
                )}&city=${city}&state=${state}&zip=${zip}&phone=${encodeURIComponent(
                    phone || ""
                )}&psfp=${psfp}`,
            {
                method: "GET",
            }
        );
        if (response.status === 200) {
            return response.json() as unknown as RewardResult;
        }

        return {
            win: false,
        };
    },
};
