import { useWebsiteConfig } from "./state/website_config";
import { usePathConfig } from "./state/path_config";
import { useOfferConfig } from "./state/offer_config";
import FacebookPixel from "./components/facebook";
import { Body } from "./layout/components/body";
import { Disclaimer } from "./components/disclaimer";
import { Pushnami } from "./components/pushnami";
import { LeadId } from "./components/lead_id";
import { TrustedCert } from "./components/trusted_cert";
import { LinkOutLoader } from "./components/linkout/linkout_loader";
import Tracking from "./components/tracking";

import "./pivot.css";
import { endPlacement } from "./helpers/placement_end_redirect";
import { useUserState } from "./state/user_state";
import {useCallback, useEffect, useState} from "react";
import { Blocking } from "./components/blocking";
import { OfferWallScript } from "./components/offer_wall/offerWallScript";
import { useDomainConfig } from "./state/domain_config";
import {startupParameters} from "./state/startup";

export type OnCompleteData = {
    firstName: string|null;
    lastName: string|null;
    email: string|null;
}

export type PivotProps = {
    onComplete?: (data: OnCompleteData) => unknown;
    domainId: string;
}

export default function Pivot(
    config: PivotProps
) {
    const [startupParamsLoaded, setStartupParamsLoaded] = useState(false);
    const state = useUserState();
    const onComplete = useCallback(
        config.onComplete ||
        (() => {
            endPlacement(state.domainConfig?.final_redirect, state);
        }),
        []
    );
    useWebsiteConfig();
    usePathConfig();
    useOfferConfig();
    useDomainConfig(config.domainId);

    useEffect(() => {
        if (startupParamsLoaded) {
            return;
        }
        setStartupParamsLoaded(true);
        const firstName = startupParameters.user.firstName;
        if (firstName) {
            state.setFirstName(firstName);
        }
        const lastName = startupParameters.user.lastName;
        if (lastName) {
            state.setLastName(lastName);
        }
        const zipCode = startupParameters.user.zipCode;
        if (zipCode) {
            state.setZipCode(zipCode);
        }
        const phone = startupParameters.user.phone;
        if (phone) {
            state.setPhone(phone);
        }
        const email = startupParameters.user.email;
        if (email) {
            state.setEmail(email);
        }
        const address1 = startupParameters.user.address1;
        if (address1) {
            state.setAddress1(address1);
        }
        const city = startupParameters.user.city;
        if (city) {
            state.setCity(city);
        }
        const addressState = startupParameters.user.state;
        if (addressState) {
            state.setState(addressState);
        }
        const day = startupParameters.user.day;
        if (day) {
            state.setDay(day);
        }
        const month = startupParameters.user.month;
        if (month) {
            state.setMonth(month);
        }
        const year = startupParameters.user.year;
        if (year) {
            state.setYear(year);
        }
        const gender = startupParameters.user.gender;
        if (gender) {
            state.setGender(gender);
        }
    }, [startupParamsLoaded]);

    const dply = startupParameters.ux.theme;
    return (
        <section className={`root-${dply} step-${state.step?.step}`}>
            <Blocking/>
            <FacebookPixel/>
            <div className="path-container min-h-screen md:min-h-0">
                <Body onComplete={() => onComplete({
                    firstName: state.firstName,
                    lastName: state.lastName,
                    email: state.email,
                })}/>
                <Disclaimer/>
                <Pushnami/>
            </div>
            <LeadId/>
            <TrustedCert/>
            <LinkOutLoader/>
            <Tracking/>
            <OfferWallScript/>
        </section>
    );
}
