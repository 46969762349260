import "./loading_bar.css";

export const LoadingBar = () => {
    return (
        <div>
            <div className="loading-container mx-4">
                <div className="loading-bar load-start">
                    <span className="loading-span"></span>
                </div>
            </div>
        </div>
    );
};
