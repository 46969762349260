import { FormEvent, useState } from "react";
import { Step, useUserState } from "../state/user_state";
import React from "react";

import { ContinueButton } from "./continue_button/continue_button";
import "./input.css";
import { validatePhone } from "../helpers/phone_validation";
import { OffsetBackground } from "./offset_background";
import { PhoneTcpa } from "./tcpa/phone_tcpa";

export const Phone = () => {
    const userState = useUserState();
    const [phone, setPhone] = useState(userState.phone || "");
    const [phoneAllowed, setPhoneAllowed] = useState(
        userState.phoneAllowed || false
    );
    const [phoneError, setPhoneError] = useState("");

    const submit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const validPhone = validatePhone(phone, setPhoneError);
        if (!validPhone) return;

        setPhoneError("");
        userState.setPhone(phone);
        userState.setPhoneAllowed(phoneAllowed);
        userState.nextStep();
    };

    return (
        <form onSubmit={submit}>
            <div className="input-container">
                <h2 className="input-statement">
                    {userState.step.subHeader ??
                        "Nice! Please Confirm your phone."}
                </h2>

                <label htmlFor="phone">Phone:</label>
                <input
                    autoFocus
                    required
                    maxLength={11}
                    name="phone"
                    value={phone}
                    type="tel"
                    onChange={(e) => setPhone(e.target.value)}
                ></input>
                {phoneError && <p style={{ color: "red" }}>{phoneError}</p>}
                <PhoneTcpa
                    phoneAllowed={phoneAllowed}
                    setPhoneAllowed={setPhoneAllowed}
                    submitText={userState.step.buttonText}
                />
                <ContinueButton
                    type="submit"
                    text={userState.step.buttonText}
                    onClick={() => userState.setPhone(phone)}
                />
            </div>
            <OffsetBackground topSpacing="4rem" />
        </form>
    );
};
