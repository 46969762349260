import { useEffect, useState } from "react";


export const useSessionKey = () => {
    const [sessionKey, setSessionKey] = useState<string>('');
    useEffect(() => {
        const storageSessionKey = sessionStorage.getItem("sessionKey");
        if (storageSessionKey)
            setSessionKey(storageSessionKey);
    }, [])

    return {sessionKey}
}