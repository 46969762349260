import { OffsetBackground } from "../../components/offset_background";
import { AlmostThere } from "../../components/path_incentives/almost_there";
import { CongratsMessage } from "../../components/path_incentives/congrats_message";
import { TopMessage } from "../../components/path_incentives/top_message";
import { RewardImage } from "../../components/reward_image/reward_image";
import { ZeetoJS } from "../../components/zeeto/zeeto_js";
import { Path } from "../../path";

export const SweepsLayout = (config: { onComplete: () => unknown }) => {
    return (
        <>
            <TopMessage />
            <AlmostThere />
            <CongratsMessage />
            <div className="inner-container pb-8 pb-10 md:mt-6 mt-4 relative z-10">
                <RewardImage />
                <div className="z-10">
                    <Path onComplete={config.onComplete} />
                    <ZeetoJS />
                </div>
            </div>
        </>
    );
};
