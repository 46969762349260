import { Step, useUserState } from "../../state/user_state";
import { RewardImage } from "./reward_image";

export const SamplesRewardImage = () => {
    const step = useUserState((s) => s.step.step);
    switch (step) {
        case Step.Unlock:
        case Step.UnlockSamples:
            return <RewardImage />;
    }
    return null;
};
