import React, { useEffect, useRef } from "react";
import { useUserState } from "../state/user_state";
import { useWebsiteConfig } from "../state/website_config";

let script: HTMLScriptElement;

export const Pushnami = () => {
    const state = useUserState();
    const loaded = useRef<boolean>(false);
    useEffect(() => {
        if (loaded.current) {
            return;
        }
        if (!state.websiteConfig) return;

        if (document.getElementById("pushnami-code")) {
            return;
        }

        loaded.current = true;
        script = document.createElement("script");
        script.id = "pushnami-code";
        script.src = `${state.websiteConfig.pushnamiApiBaseUrl}/scripts/v1/push/${state.websiteConfig.webpushWebsiteApiKey}`;
        script.async = true;
        script.onload = async (ev) => {
            if (
                window.Pushnami &&
                window.Pushnami.psfp &&
                window.Pushnami.psfp.check
            ) {
                await window.Pushnami.psfp.check();
                state.setPsfp(
                    window.Pushnami.psfp.data ||
                        localStorage.getItem("pushnami-psfp") ||
                        ""
                );
            }
        };
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, [state.websiteConfig]);
    return null;
};
